import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../../api";

const Container = styled.div`
  padding: 20px;
  border: 2px dashed #ccc;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  text-align: center;
`;

const SubTitle = styled.h3`
  margin-top: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckboxGroup = styled.div`
  flex: 1;
  padding: 10px;
`;

const CheckboxLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-top: 10px;
`;

const GradeSelectContainer = styled.div`
  margin-top: 20px;
`;

const GradeSelectLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const GradeSelect = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Evaluation54 = ({ onSurveyUpdate }) => {
  const location = useLocation();
  const [initialFormData, setInitialFormData] = useState(null);
  const [formData, setFormData] = useState({
    negative: Array(4).fill(0),
    positive: Array(4).fill(0),
    additionalComments1: '',
    additionalComments2: '',
    grade: '',
    surveyNumber: 54,
    kindergartenId: '',
    year: new Date().getFullYear()
  });
  const [currentExpertId, setCurrentExpertId] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const kindergartenId = params.get('kindergartenId');
    const expertId = params.get('expertId');
    setCurrentExpertId(expertId);

    setFormData(prevState => ({
      ...prevState,
      kindergartenId
    }));

    const fetchData = async () => {
      try {
        const response = await api.getExpertGrades2(expertId, formData.year, kindergartenId, formData.surveyNumber);
        if (response.data.success && response.data.data) {
          setFormData(prevState => ({
            ...prevState,
            ...response.data.data
          }));
          setInitialFormData(response.data.data);
        } else {
          setInitialFormData(formData);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setInitialFormData(formData);
        } else {
          console.error('Error fetching data:', error);
        }
      } finally {
        setIsDataLoaded(true);
      }
    };

    fetchData();
  }, [location]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (JSON.stringify(initialFormData) !== JSON.stringify(formData)) {
        const message = 'You have unsaved changes, do you really want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [initialFormData, formData]);

  const handleCheckboxChange = (e, type, index) => {
    const { checked } = e.target;
    setFormData(prevState => {
      const updatedList = [...prevState[type]];
      updatedList[index] = checked ? 1 : 0;

      return {
        ...prevState,
        [type]: updatedList
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'grade' ? Number(value) : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    
    if (formData.grade === 0||formData.grade === '') {
        toast.error("您还没选择评级，请选择评级。");
        return;
    }

    const playload = {
        expertId: currentExpertId,
        formData: formData,
    };

    // 提交表单数据
    api.saveExpertGrades2(playload)
        .then(() => {
            // 更新 initialFormData
            setInitialFormData(formData);
            toast.success("保存成功");
            // 调用父组件的更新函数
            onSurveyUpdate(formData.surveyNumber);
        })
        .catch(error => {
            console.error('Error saving data:', error);
            toast.error("保存失败，请重试");
        });
};

  if (!isDataLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <ToastContainer />
      <Title>考查要点 5.4</Title>
      <SubTitle>消极表现</SubTitle>
      <CheckboxContainer>
        <CheckboxGroup>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 0)}
              checked={formData.negative[0] === 1}
            />
            幼儿园没有针对特殊需要幼儿的管理制度。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 1)}
              checked={formData.negative[1] === 1}
            />
            能为患病幼儿提供服务（如按正规医院处方服药，注意饮食禁忌，做好生活照料工作等）。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 2)}
              checked={formData.negative[2] === 1}
            />
            未做到对体弱儿、肥胖儿的规范管理的要求，未做到全覆盖（如定期筛查和记录，制定特定的膳食计划、活动计划等）。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 3)}
              checked={formData.negative[3] === 1}
            />
            教师未经过专业培训，无法有效地识别和理解幼儿的康复需求，导致与家长沟通存在问题，沟通制度存在问题。
          </CheckboxLabel>
        </CheckboxGroup>
      </CheckboxContainer>

      <Textarea
        name="additionalComments1"
        value={formData.additionalComments1}
        onChange={handleChange}
        placeholder="如有其他证据，请写在下方："
      />

      <SubTitle>积极表现</SubTitle>
      <CheckboxContainer>
        <CheckboxGroup>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 0)}
              checked={formData.positive[0] === 1}
            />
            重视有特殊需要的幼儿，根据实际需要配备资源教师，形成个案计划及跟踪随访矫治记录。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 1)}
              checked={formData.positive[1] === 1}
            />
            保健医和保教人员配合，对需要医治或矫正的幼儿给予必要的照料，并做好个人档记录。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 2)}
              checked={formData.positive[2] === 1}
            />
            教师接受了专业培训，能够更好地理解和满足特殊需要幼儿的需求，确保他们在班级中有平等的参与机会。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 3)}
              checked={formData.positive[3] === 1}
            />
            特殊需要幼儿在班级中能良好的适应班级环境，有良好的社交活动。
          </CheckboxLabel>
        </CheckboxGroup>
      </CheckboxContainer>

      <Textarea
        name="additionalComments2"
        value={formData.additionalComments2}
        onChange={handleChange}
        placeholder="如有其他证据，请写在下方："
      />

      <GradeSelectContainer>
        <GradeSelectLabel>请根据上述表现对该考查要点进行评级：</GradeSelectLabel>
        <GradeSelect

          name="grade"
          value={formData.grade}
          onChange={handleChange}
        >
          <option value="">请选择评级</option>
          <option value={1}>优秀</option>
          <option value={2}>良好</option>
          <option value={3}>及格</option>
          <option value={4}>不及格</option>
          <option value={5}>不了解</option>
        </GradeSelect>
      </GradeSelectContainer>


      <Button onClick={handleSubmit}>保存</Button>
    </Container>
  );
};

export default Evaluation54;
