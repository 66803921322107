import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import api from "../api";
import {toast,ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Authenticated from "../utils/Authenticated";

const Styles = styled.div`
 background: lavender;
 padding: 20px;

 h1 {
   border-bottom: 1px solid white;
   color: #3d3d3d;
   font-family: sans-serif;
   font-size: 20px;
   font-weight: 600;
   line-height: 24px;
   padding: 10px;
   text-align: center;
 }

 form {
   background: white;
   border: 1px solid #dedede;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   margin: 0 auto;
   max-width: 500px;
   padding: 30px 50px;
 }

 input {
   border: 1px solid #d9d9d9;
   border-radius: 4px;
   box-sizing: border-box;
   padding: 10px;
   width: 100%;
 }

 label {
   color: #3d3d3d;
   display: block;
   font-family: sans-serif;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 5px;
 }

 .error {
   color: red;
   font-family: sans-serif;
   font-size: 12px;
   height: 30px;
 }

 .submitButton {
   background-color: #6976d9;
   color: white;
   font-family: sans-serif;
   font-size: 14px;
   margin: 20px 0px;
`;



const Wrapper = styled.div`
  padding: 240px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('https://dch.georgia.gov/sites/dch.georgia.gov/files/styles/3_2_2106px_x_1404px/public/2020-02/pck%20kids.jpg?h=9d96f97f&itok=npnQiCp_');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.8);
  width: 80vw;
  height: 80vh;
  margin: 0 auto; // 居中显示
`;


const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  margin: 0 auto;
`;

const Cell = styled.div`
  flex: 1;
  padding: 5px;
  background-color: white;
  border: 1px solid #ccc;
  text-align: center;
`;

const CellHead = styled.div`
  flex: 1;
  padding: 5px;  
  background: linear-gradient(#ffd700, #808080);
  border: 1px solid #ccc;
  text-align: center;
  font-weight: bold;
  font-size: 34px;
`;


const LoginView = () => {
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        const { username, password } =data
        const payload = { username, password }

        try {
            const res = await api.loginAccount(payload)
            toast.success('Login Successfully!', {autoClose: 500});

            // 读取用户授权
            const account = await Authenticated()
            //把account中的role属性辅助给一个新的变量role
            const {role} = account
            //如果role的值是province，就跳转到省级页面,如果role的值是school，就跳转到学校页面,如果是区就转到区页面，如果是市就转到市页面
            if(role === "province"||role === "city"||role === "district"){
                navigate("/reports/ReportShow");
            }else if(role === "school"){
                navigate("/reports/ReportShow");
            }

            // window.location.assign("/reports/account");
        } catch(err) {
            console.log(err.response)
            toast.success('用户名或密码错误!', {autoClose: 500});
        }
    };

    const getCounts = async () => {
        const word = await api.getAccounts()
        console.log(word)
    }

    const getAuthenticated = async () => {
        const accout = await Authenticated()
        navigate("/words/wordeditor");
    }



    return(
        <Wrapper>
            <ToastContainer />
            <Row>
                <CellHead>
                    长春市基础教育质量监测学校报告
                </CellHead>
            </Row>
            <Row>
                <Cell>
                    <Styles>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input {...register("username",{ required: true, maxLength: 20 })} placeholder="账号" />
                            <input type="password" {...register("password",{ required: true, maxLength: 20 })} placeholder="密码" />
                            <input type="submit" className="submitButton" value="登录"/>
                        </form>
                        {/*<Button onClick={getAuthenticated}>读取</Button>*/}
                    </Styles>
                </Cell>

            </Row>


        </Wrapper>

    )

}

export default LoginView