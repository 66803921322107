import axios from "axios";
import {BASE_URL} from "../utils/url";
let api={}
// console.log(BASE_URL)
// if(process.env.NODE_ENV==='development')
//     api = axios.create({
//         withCredentials: true,//开发环境true，生产环境false
//         baseURL:BASE_URL+'api'
// })
// else
//     api = axios.create({

//         withCredentials: false,//开发环境true，生产环境false
//         baseURL:BASE_URL+'api'
// })

api = axios.create({

    withCredentials: false,//开发环境true，生产环境false
    baseURL:BASE_URL+'api'
})

//幼儿园管理
export const getEvaluations = (kindergartenId, year) => api.get(`/evaluation/${kindergartenId}/${year}`);
export const getEvaluationsByYear = (year) => api.get(`/getEvaluationsByYear/${year}`)
export const saveEvaluation = payload => api.post(`/saveEvaluation`,payload)
export const insertKindergarten = payload => api.post(`/kindergarten`,payload)
export const getKindergartens = payload => api.get(`/kindergartens`,payload)
export const kindergartenNamesByIds =payload => api.post(`/kindergartenNamesByIds`,payload)
export const getKindergartenById = payload => api.get(`/kindergarten/${payload}`)
export const updateKindergartenById = (id, payload) => api.put(`/kindergarten/${id}`, payload);
export const deleteKindergartenById = payload => api.delete(`/kindergarten/${payload}`)

//教师管理
export const insertTeacher = payload => api.post(`/teacher`,payload)
export const getTeachers = payload => api.get(`/teachers`,payload)
export const getTeacherById = payload => api.get(`/teacher/${payload}`)
export const getTeachersByIds = (idsQueryString) => api.get(`/teachersByIds?ids=${idsQueryString}`);

export const updateTeacherById = payload => api.put(`/teacher`,payload)
export const deleteTeacherById = payload => api.delete(`/teacher/${payload}`)
export const teacherLogin = payload => api.post(`/teacher/login`,payload)

export const updateTeacherPassword = payload => api.put(`/teacher/password`,payload)
export const resetPasswordRequest = payload => api.post(`/resetPasswordRequest`,payload)
export const resetPassword = payload => api.post(`/resetPassword`,payload)

//oss or system
export const deleteFile = payload => api.put(`/DeleteFile`,payload)
export const getAuthenticated = payload => api.get(`/getAuthenticated`,payload)
export const getDistributionData = () => api.get(`/getDistributionData`)
export const saveDistributionData = payload => api.post(`/saveDistributionData`,payload)

//专家
export const insertExperts = payload => api.post(`/expert`,payload)    
export const expertLogin = payload => api.post(`/expert/login`,payload)
export const getExperts = payload => api.get(`/experts`,payload)
export const getExpertById =  (expertId) => api.get(`/expert/${expertId}`)
export const updateExpertPassword = payload => api.put(`/expert/password`,payload)
export const updateExpertSettings = payload => api.post(`/expert/Settings`,payload)
export const getExpertSettings2 = (expertId) => api.get(`/expert/Settings2/${expertId}`)   
export const updateExpertSettings2 = payload => api.post(`/expert/Settings2`,payload)  
export const updateExpertById = payload => api.post(`/expert/update`,payload)
export const saveExpertGrades = payload => api.post(`/expert/grades`,payload)
export const saveExpertGrades2 = payload => api.post(`/expert/grades2`,payload)
export const getExpertGrades = (expertID, year) => api.get(`/ExpertGrades/${expertID}/${year}`);
export const getExpertGradesById = (expertID, year) => api.get(`/ExpertGrades/${expertID}/${year}`);
export const getExpertGrades2 = (expertId, year, kindergartenId, surveyNumber) => api.get(`/expertGrades2/${expertId}/${year}/${kindergartenId}/${surveyNumber}`);
export const getExpertGrades2AllSurvey = (expertId, year, kindergartenId) => api.get(`/expertGrades2AllSurvey/${expertId}/${year}/${kindergartenId}`);
export const getAllExpertGrades2 = (year) => api.get(`/getAllExpertGrades2/${year}`);
export const getAllQuantitativeGrades = (year) => api.get(`/getAllQuantitativeGrades/${year}`);
const apis = {  
    getEvaluations,
    getEvaluationsByYear,
    saveEvaluation,
    insertKindergarten,
    getKindergartens,
    getKindergartenById,
    kindergartenNamesByIds,
    updateKindergartenById,
    deleteKindergartenById,

    insertTeacher,
    getTeachers,
    getTeacherById,
    getTeachersByIds,
    updateTeacherById,
    deleteTeacherById,
    teacherLogin,
    updateTeacherPassword,
    resetPasswordRequest,
    resetPassword,
    deleteFile,
    getAuthenticated,
    getDistributionData,
    saveDistributionData,

    insertExperts,
    expertLogin,
    getExperts,
    getExpertById,
    updateExpertPassword,
    updateExpertSettings,
    getExpertSettings2,
    updateExpertSettings2,
    updateExpertById,
    saveExpertGrades,
    saveExpertGrades2,
    getExpertGradesById,
    getExpertGrades2,
    getExpertGrades2AllSurvey,
    getAllExpertGrades2,
    getAllQuantitativeGrades,
}
export default apis