import React from 'react';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import KinderGardenPage from "./KinderGardenPage";
import ExpertsPage from "./ExpertsPage";
import SettingsPage from "./SettingsPage";
import SecondStageSettingsPage from "./SecondStageSettingsPage";

const Dashboard = () => {
  return (
    <div style={{ display: 'flex', minHeight: '100vh' }}>
      <div style={{ width: '200px', background: '#f0f0f0', padding: '20px' }}>
        <ul style={{ listStyleType: 'none', padding: 0, fontSize: '16px' }}>
          <li style={{ margin: '10px 0', borderRadius: '5px', transition: 'background-color 0.2s ease-in-out' }}>
            <Link to="kindergarden" style={{ display: 'block', padding: '10px 20px', textDecoration: 'none', color: '#333', background: '#e9ecef', borderRadius: '5px' }}>
              幼儿园管理
            </Link>
          </li>
          <li style={{ margin: '10px 0', borderRadius: '5px', transition: 'background-color 0.2s ease-in-out' }}>
            <Link to="expert" style={{ display: 'block', padding: '10px 20px', textDecoration: 'none', color: '#333', background: '#e9ecef', borderRadius: '5px' }}>
              专家用户管理
            </Link>
          </li>
          <li style={{ margin: '10px 0', borderRadius: '5px', transition: 'background-color 0.2s ease-in-out' }}>
            <Link to="settings" style={{ display: 'block', padding: '10px 20px', textDecoration: 'none', color: '#333', background: '#e9ecef', borderRadius: '5px' }}>
              全局设置
            </Link>
          </li>
          <li style={{ margin: '10px 0', borderRadius: '5px', transition: 'background-color 0.2s ease-in-out' }}>
            <Link to="second-stage-settings" style={{ display: 'block', padding: '10px 20px', textDecoration: 'none', color: '#333', background: '#e9ecef', borderRadius: '5px' }}>
              阶段二设置
            </Link>
          </li>
        </ul>
      </div>
      <div style={{ flex: 1, padding: '20px' }}>
        <Routes>
          <Route path="kindergarden" element={<KinderGardenPage />} />
          <Route path="expert" element={<ExpertsPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="second-stage-settings" element={<SecondStageSettingsPage />} />
          <Route path="/" element={<KinderGardenPage />} />
        </Routes>
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
