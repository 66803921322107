import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  text-align: center;
  padding: 20px;
  background-color: #f0f0f0; // 或者任何你喜欢的颜色
  color: #666;
`;

const Footer = () => (
  <FooterContainer>
    <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer" style={{color: '#666'}}>
      京ICP备18039988号-5
    </a>
    <div>如遇问题，请发邮件至：changchunbaojiao@126.com</div>
  </FooterContainer>
);

export default Footer;
