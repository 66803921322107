import React, { useState, useEffect } from 'react';
import api from '../api'; // 假设你有一个API文件来处理HTTP请求
import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const PageContainer = styled.div`
  padding: 20px;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const SectionContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

const FlexContainer = styled.div`
  flex: 1;
`;

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const Button = styled.button`
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  background-color: #4CAF50; /* Green */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const Ul = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const Li = styled.li`
  margin: 5px 0;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate; /* 让单元格之间可以有间距 */
  border-spacing: 0 10px; /* 设置单元格之间的间距 */
`;

const Th = styled.th`
  padding: 10px;
  text-align: left;
  background-color: #4CAF50;
  color: white;
  border-bottom: 1px solid #ddd;
`;

const Td = styled.td`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  background-color: #f9f9f9; /* 设置一个轻微的背景色 */
  /* 使用 :first-child 和 :last-child 选择器添加圆角 */
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const EditInput = styled.input`
  padding: 5px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 95%; /* 编辑框内部留有边距 */
`;

const ButtonTable = styled.button`
  padding: 5px 10px;
  margin-right: 5px; /* 按钮之间的间距 */
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Select = styled.select`
  padding: 5px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: calc(100% - 10px); /* 调整宽度以适应边距 */
`;

const UserInfoContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: right;
  font-size: 18px;
  color: #3d3d3d;
  z-index: 1000;
`;

const KinderGardenPage = () => {
    const location = useLocation(); // 使用useLocation钩子
    const navigate = useNavigate();
    const [kindergartens, setKindergartens] = useState([]);
    const [selectedKindergarten, setSelectedKindergarten] = useState('');
    const [kindergartenDetails, setKindergartenDetails] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState('');
    const [editAddress, setEditAddress] = useState('');
    const [teachersDetails, setTeachersDetails] = useState([]);
    const [editingPasswordIndex, setEditingPasswordIndex] = useState(null);
    const [password, setPassword] = useState("");

    let { teacherId, kindergartenId, realName } = location.state || {};

    useEffect(() => {
        const fetchKindergartens = async () => {
            try {
                const response = await api.getKindergartens();
                // 对幼儿园列表进行排序
                const sortedKindergartens = response.data.data.sort((a, b) => {
                    // 获取年份（前4个字符）
                    const yearA = a.name.substring(0, 4);
                    const yearB = b.name.substring(0, 4);
                    
                    if (yearA === yearB) {
                        // 如果年份相同，提取空格后点号前的数字
                        const numA = parseInt(a.name.split(' ')[1].split('.')[0]);
                        const numB = parseInt(b.name.split(' ')[1].split('.')[0]);
                        return numA - numB;
                    }
                    // 年份倒序排列
                    return yearB - yearA;
                });
                
                setKindergartens(sortedKindergartens);
            } catch (error) {
                console.error("Failed to fetch kindergartens:", error);
            }
        };

        fetchKindergartens();
    }, []);

    const fetchTeachersDetails = async (teacherIds) => {
        try {
            // 将ID数组转换为逗号分隔的字符串
            const idsQueryString = teacherIds.join(',');
            // 使用getTeachersByIds函数发送请求
            const response = await api.getTeachersByIds(idsQueryString);

            setTeachersDetails(response.data.data);            
        } catch (error) {
            console.error("Failed to fetch teachers details:", error);
        }
    };




    const handleSelectKindergarten = async (e) => {
        const selectedId = e.target.value;
        setSelectedKindergarten(selectedId);

        try {
            const response = await api.getKindergartenById(selectedId); // 假设这个函数根据ID返回幼儿园的详细信息            
            setKindergartenDetails(response.data.data);
            fetchTeachersDetails(response.data.data.teachers);
        } catch (error) {
            console.error("Failed to fetch kindergarten details:", error);
        }
    };

    const handleToggleEvaluationFinished = (evaluationId, finished) => {
        // 直接更新本地状态以反映变化，不进行API调用
        setKindergartenDetails(prev => ({
            ...prev,
            evaluations: prev.evaluations.map(evaluation =>
                evaluation._id === evaluationId ? { ...evaluation, finished: !finished } : evaluation
            )
        }));
        console.log('Toggled evaluation:', kindergartenDetails);
    };


    const handleEdit = () => {
        if (kindergartenDetails) {
            setIsEditing(true);
            setEditName(kindergartenDetails.name);
            setEditAddress(kindergartenDetails.address);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    const handleLogout = () => {
        // 这里可以添加登出逻辑，例如清除用户状态、发送登出请求等
        navigate('/login'); // 跳转到登录页面
      };

    const handleSaveChanges = async () => {
        // 如果没有选择幼儿园或者幼儿园详情为空，直接返回
        if (!selectedKindergarten || !kindergartenDetails) {
            console.error("No kindergarten selected or details are unavailable");
            return;
        }

        // 准备发送到服务器的数据
        const updatedDetails = {
            name: isEditing ? editName : kindergartenDetails.name,
            address: isEditing ? editAddress : kindergartenDetails.address,
            evaluations: kindergartenDetails.evaluations,
        };
       

        try {
            // 使用API发送更新请求到服务器
            await api.updateKindergartenById(selectedKindergarten, updatedDetails);
            // 更新成功后，更新本地状态
            setKindergartenDetails(prevDetails => ({
                ...prevDetails,
                name: updatedDetails.name,
                address: updatedDetails.address
            }));
            setIsEditing(false); // 退出编辑状态
        } catch (error) {
            console.error("Failed to save changes:", error);
        }
    };

    const handleEditTeacher = (index) => {
        // 标记教师为编辑模式，并初始化临时编辑数据
        const newTeachers = [...teachersDetails];
        newTeachers[index].isEditing = true;
        newTeachers[index].editRealName = newTeachers[index].realName;
        newTeachers[index].editEmail = newTeachers[index].email;
        newTeachers[index].editRole = newTeachers[index].role;
        setTeachersDetails(newTeachers);
        
    };

    const handleSaveTeacher = async (index) => {
        const teacher = teachersDetails[index];

        api.updateTeacherById({
            id: teacher._id, // 确保这里传递教师的ID
            realName: teacher.editRealName,
            email: teacher.editEmail,
            role: teacher.editRole
        });

        // 假设保存成功后更新状态
        const newTeachers = [...teachersDetails];
        newTeachers[index].realName = newTeachers[index].editRealName;
        newTeachers[index].email = newTeachers[index].editEmail;
        newTeachers[index].role = newTeachers[index].editRole;
        newTeachers[index].isEditing = false;
        setTeachersDetails(newTeachers);
    };

    const handleChange = (index, field, value) => {
        // 更新临时编辑数据
        const newTeachers = [...teachersDetails];
        newTeachers[index][field] = value;
        setTeachersDetails(newTeachers);
    };

    const handleCancelEdit = (index) => {
        // 取消编辑状态，不保存更改
        const newTeachers = [...teachersDetails];
        newTeachers[index].isEditing = false;
        setTeachersDetails(newTeachers);
    };

    // 处理保存密码的逻辑
    const handleSavePassword = async (teacherId, index) => {
        if (!password) {
            
            alert("请输入密码");
            return;
        }

        // 调用API更新密码，这里假设你有一个适当的API端点
        try {
            await api.updateTeacherPassword({ id: teacherId, newPassword: password });
            alert("密码更新成功");
            // 重置状态
            setPassword("");
            setEditingPasswordIndex(null);
        } catch (error) {
            console.error("更新密码失败:", error);
            alert("密码更新失败");
        }
    };

    return (
        <PageContainer>
            <UserInfoContainer>
                {realName} 
                <button onClick={handleLogout} style={{ marginLeft: '20px', padding: '5px 10px', fontSize: '16px', cursor: 'pointer', borderRadius: '5px', border: 'none', backgroundColor: '#6976d9', color: 'white' }}>
                    登出
                </button>
                
            </UserInfoContainer>
            <h1>幼儿园管理</h1>
            <StyledSelect value={selectedKindergarten} onChange={handleSelectKindergarten}>
                <option value="">请选择幼儿园</option>
                {kindergartens.map((kg) => (
                    <option key={kg._id} value={kg._id}>{kg.name}</option>
                ))}
            </StyledSelect>

            <SectionContainer>
                <FlexContainer>
                    {isEditing ? (
                        <>
                            <Input value={editName} onChange={(e) => setEditName(e.target.value)} />
                            <Input value={editAddress} onChange={(e) => setEditAddress(e.target.value)} />
                            <Button onClick={handleCancel}>取消</Button>
                        </>
                    ) : (
                        <>
                            <p>名称：{kindergartenDetails?.name}</p>
                            <p>地址：{kindergartenDetails?.address}</p>
                            <Button onClick={handleEdit}>编辑</Button>
                        </>
                    )}
                </FlexContainer>

                <FlexContainer>
                    <h3>评估报告状态：</h3>
                    {kindergartenDetails?.evaluations.map(evaluation => (
                        <div key={evaluation._id}>
                            <p>年份: {evaluation.year}</p>
                            <p>完成状态: {evaluation.finished ? '已完成' : '未完成'}</p>
                            <Button onClick={() => handleToggleEvaluationFinished(evaluation._id, evaluation.finished)}>
                                {evaluation.finished ? '标记为未完成' : '标记为已完成'}
                            </Button>
                        </div>
                    ))}
                </FlexContainer>
            </SectionContainer>

            {selectedKindergarten && (
                <Button onClick={handleSaveChanges}>保存所有更改</Button>
            )}

            {/* 教师列表的表格形式 */}
            <Table>
                <thead>
                    <tr>
                        <Th>姓名</Th>
                        <Th>电子邮件</Th>
                        <Th>角色</Th>
                        <Th>操作</Th>
                    </tr>
                </thead>
                <tbody>
                    {teachersDetails.map((teacher, index) => (
                        <tr key={teacher._id}>
                            <Td>
                                {teacher.isEditing ? (
                                    <EditInput
                                        type="text"
                                        value={teacher.editRealName}
                                        onChange={(e) => handleChange(index, 'editRealName', e.target.value)}
                                    />
                                ) : (
                                    teacher.realName
                                )}
                            </Td>
                            <Td>
                                {teacher.email}
                            </Td>
                            <Td>
                                {teacher.isEditing ? (
                                    <Select
                                        value={teacher.editRole}
                                        onChange={(e) => handleChange(index, 'editRole', e.target.value)}
                                    >
                                        <option value="guest">游客</option>
                                        <option value="teacher">幼儿园教师</option>
                                        <option value="admin">系统管理员</option>
                                    </Select>
                                ) : (
                                    teacher.role
                                )}
                            </Td>

                            <Td>
                                {teacher.isEditing ? (
                                    <>
                                        <ButtonTable onClick={() => handleSaveTeacher(index)}>保存</ButtonTable>
                                        <ButtonTable onClick={() => handleCancelEdit(index)}>取消</ButtonTable>
                                    </>
                                ) : (
                                    <ButtonTable onClick={() => handleEditTeacher(index)}>编辑</ButtonTable>
                                )}
                            </Td>
                            <Td>
                                {index === editingPasswordIndex ? (
                                    <>
                                        <EditInput
                                            type="password"
                                            placeholder="新密码"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <ButtonTable onClick={() => handleSavePassword(teacher._id, index)}>
                                            保存密码
                                        </ButtonTable>
                                    </>
                                ) : (
                                    <ButtonTable onClick={() => setEditingPasswordIndex(index)}>
                                        修改密码
                                    </ButtonTable>
                                )}
                            </Td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </PageContainer>
    );
};

export default KinderGardenPage;
