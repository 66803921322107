import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import api from '../api';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from "react-router-dom"; // 导入useLocation
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { erf } from 'mathjs';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Select = styled.select`
  width: 200px;
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  &:nth-child(odd) {
    background-color: #f9f9f9;  // 为奇数行设置浅灰色背景
  }

  &:nth-child(even) {
    background-color: #e9e9e9;  // 为偶数行设置稍深一点的灰色背景
  }
`;


const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ccc;
`;

const GradeCircle = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin-right: 5px;
  cursor: pointer;
  background-color: ${props => props.selected ? '#4CAF50' : 'transparent'};
  color: ${props => props.selected ? 'white' : 'black'};
`;

const AnswerContainer = styled.div`
  padding: 10px 0; // 在内容的上下添加一些内间距
  border-bottom: 1px solid #ccc; // 底部边框作为分隔线
  &:last-child {
    border-bottom: none; // 最后一个元素不需要底部边框
  }
`;

const AttachmentList = styled.ul`
  list-style-type: none; // 去除默认的列表项目符号
  padding-left: 0; // 去除默认的内边距
  margin-top: 10px; // 和答案之间的间隔
`;
const AttachmentListItem = styled.li`
  padding: 5px 0; // 在列表项之间添加垂直间隔
  cursor: pointer; // 添加这一行
  transition: background-color 0.2s ease-in-out; // 添加平滑过渡效果

  &:hover {
    background-color: #f0f0f0; // 鼠标悬停时的背景颜色
  }
`;



const EvaluationTitle = styled.h2`
  margin-top: 24px;
  text-align: left;
`;

const EvaluationGuidance = styled.h3`
  margin-top: 16px;
  text-align: left;
`;

const EvaluationPoint = styled.li`
  margin-top: 8px;
  text-align: left;
`;


const EvaluationQuestion = styled.strong`
  display: block;
  font-weight: bold;
  margin-bottom: 16px;
`;


const Button = styled.button`
  padding: 1px 20px;
  margin: 5px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: all 0.3s;

  &.highlight {
    border-color: #007bff;
    background-color: #007bff;
    color: white;
  }
`;


const evaluationItems = [
  {
    id: 1,
    title: "评估指标1.办园方向之党建工作：强化党组织战斗堡垒作用",
    guidance: "自评要点：",
    points: [
      "举证说明党的组织建设、制度建设和工作机制；",
      "举证说明领导班子建设情况；",
      "举证说明工会、共青团等群团组织的作用；",
      "举证说明党支部参与幼儿园发展规划、年度工作计划的制定情况。"
    ],
    wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
    questions: [
      { questionText: "本园当前现状写实（限600字以内）", answer: "" },
      { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
      { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
    ]
  },
  {
    id: 2,
    title: "评估指标2.办园方向之品德启蒙：全面贯彻党的教育方针，落实立德树人根本任务。",
    guidance: "自评要点：",
    points: [
      "有哪些做法体现了把社会主义核心价值观融入保育教育全过程",
      "培育幼儿良好品德和行为习惯养成的具体举措",
    ],
    wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
    questions: [
      { questionText: "①本园当前现状写实（限600字以内）", answer: "" },
      { questionText: "②此项指标较前一年度的进步之处（限200字以内）", answer: "" },
      { questionText: "③目前存在的问题及未来改进计划（限200字以内）", answer: "" }
    ]
  },
  {
    id: 3,
    title: "评估指标3.办园方向之科学理念：树立科学保育教育理念，确保正确办园方向。",
    guidance: "自评要点：",
    points: [
      "举证说明幼儿园保教活动如何做到遵循幼儿身心发展规律和学前教育规律；",
      "举证说明幼儿园如何做到坚持以游戏为基本活动；",
      "举证说明幼儿园如何做到支持和满足幼儿学习与发展的需要。"
    ],
    wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
    questions: [
      { questionText: "本园当前现状写实（限600字以内）", answer: "" },
      { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
      { questionText: "目前存在的问题及未来改进计划（限200字以内）。", answer: "" },
    ]
  },
  {
    id: 4,
    title: "评估指标4.保育与安全之卫生保健：加强膳食营养、疾病预防、健康检查等工作，强化医护保健人员配备。",
    guidance: "自评要点：",
    points: [
      "卫生保健工作制度的完善和落实；",
      "举证说明如何确保幼儿膳食营养均衡；",
      "传染病的防控及应对措施；",
      "卫生保健人员的资质与配备。"
    ],
    wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
    questions: [
      { questionText: "本园当前现状写实（限600字以内）", answer: "" },
      { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
      { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
    ]
  },
  {
    id: 5,
    title: "评估指标5.保育与安全之生活照料：建立合理的生活常规。",
    guidance: "自评要点：",
    points: [
      "结合实例说明如何引导幼儿养成良好的生活卫生习惯；",
      "结合实例说明如何引导幼儿形成自我服务能力和养成劳动习惯；",
      "说明体格锻炼活动的时间和内容；",
      "对有特殊需要幼儿（例如自闭倾向或攻击倾向的幼儿）照料的可用资源（包括人力配备、场地和设施等）和预案。"
    ],
    wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
    questions: [
      { questionText: "本园当前现状写实（限600字以内）", answer: "" },
      { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
      { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
    ]
  },
  {
    id: 6,
    title: "评估指标6.保育与安全之安全防护：安全保障和制度落实，确保幼儿生命安全和身心健康。",
    guidance: "自评要点：",
    points: [
      "列出安全管理制度并说明具体措施；",
      "列出环境、设施设备等日常检查维护的相关制度；",
      "结合实例说明如何把安全教育融入幼儿一日生活。"
    ],
    wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
    questions: [
      { questionText: "本园当前现状写实（限600字以内）", answer: "" },
      { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
      { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
    ]
  },
  {
    id: 7,
    title: "评估指标7.教育过程之活动组织：坚持以游戏为基本活动。",
    guidance: "自评要点：",
    points: [
      "各班级保教计划制定的依据、过程和制度；",
      "说明各年龄段幼儿一日活动的时间和内容安排；",
      "游戏时间的安排、游戏环境的创设和游戏材料的提供；",
      "为鼓励幼儿自主探究、自主思考、积极表达，采取了哪些措施，有何成效和体现；",
      "列出幼儿园当前主要参考或使用的课程教学类资源，如何体现了五领域整合；",
      "结合实例说明教师如何做到寓教育于生活和游戏中；",
      "列出大班下学期组织的入学准备活动。"
    ],
    wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
    questions: [
      { questionText: "本园当前现状写实（限600字以内）", answer: "" },
      { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
      { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
    ]
  },
  {
    id: 8,
    title: "评估指标8.教育过程之师幼互动：理解尊重幼儿并支持其有意义地学习。",
    guidance: "自评要点：",
    points: [
      "以具体活动为例说明教师与幼儿在活动中的情绪状态；",
      "结合实例说明教师如何支持幼儿自主选择和做决策；",
      "结合实例说明教师如何进行幼儿行为的观察分析与指导，关于每个幼儿行为特点和成长变化的观察记录的频次和数量如何，是否有形成记录存档；",
      "结合实例说明教师如何善于发现教育契机有效支持幼儿的学习，重视幼儿活动前的计划环节和活动后的总结环节；",
      "结合实例说明教师如何尊重并回应幼儿的想法与问题；",
      "结合实例说明教师如何尊重幼儿发展的个体差异；",
      "实例说明教师如何促进幼儿在原有水平上的全面发展;"
    ],
    wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
    questions: [
      { questionText: "本园当前现状写实（限600字以内）", answer: "" },
      { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
      { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
    ]
  },
  {
    id: 9,
    title: "评估指标9.教育过程之家园共育：强化家园协同育人。",
    guidance: "自评要点：",
    points: [
      "建设家园关系的具体举措；",
      "如何通过制度化活动让家长参与和支持幼儿园工作；",
      "多途径开展对家长进行科学育儿指导的制度、举措等；",
      "幼儿园与家庭、社区的协同育人机制及具体做法。"
    ],
    wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
    questions: [
      { questionText: "本园当前现状写实（限600字以内）", answer: "" },
      { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
      { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
    ]
  },
  {
    id: 10,
    title: "评估指标10.环境创设之空间设施：积极创设丰富适宜、富有童趣、有利于支持幼儿学习探索的教育环境。",
    guidance: "自评要点：",
    points: [
      "用数字说明幼儿园规模与班额；",
      "用面积数据和图片说明班级空间的布局与利用；",
      "列出班级区域活动空间（含走廊）的面积和功能；",
      "列出户外场地的设计与利用情况；",
      "举证说明幼儿园设施设备满足幼儿学习与游戏需要的情况。"
    ],
    wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
    questions: [
      { questionText: "本园当前现状写实（限600字以内）", answer: "" },
      { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
      { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
    ]
  },
  {
    id: 11,
    title: "评估指标11.环境创设之玩具材料：配备数量充足、种类多样的玩教具和图画书，有效支持保育教育工作科学实施。",
    guidance: "自评要点：",
    points: [
      "说明玩具材料的种类、数量、利用率；",
      "说明图画书的数量、调整更新周期和利用率。"
    ],
    wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
    questions: [
      { questionText: "本园当前现状写实（限600字以内）", answer: "" },
      { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
      { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
    ]
  },
  {
    id: 12,
    title: "评估指标12.教师队伍之师德师风：加强教师师德工作。",
    guidance: "自评要点：",
    points: [
      "说明师德师风相关的制度和要求；",
      "列出本园开展的师德师风建设活动；",
      "列出本园教师获得的与师德师风相关的表彰奖励；",
      "列出本园对教职工开展人文关怀和各种帮扶的举措。"
    ],
    wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
    questions: [
      { questionText: "本园当前现状写实（限600字以内）", answer: "" },
      { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
      { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
    ]
  },
  {
    id: 13,
    title: "评估指标13.教师队伍之人员配备： 符合《幼儿园教职工配备标准》（暂行）的要求。",
    guidance: "自评要点：",
    points: [
      "列出各类教职工配备的数量；",
      "说明教职工持证上岗情况；",
      "说明保教人员的学历和所持证书情况；",
      "列出本园教师获得市级以上名师、名园长或同类荣誉称号的人次及名称；",
      "统计近三年专任教师的离职率；",
      "说明园长的工作经历并举例说明园长的专业领导力表现。"
    ],
    wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
    questions: [
      { questionText: "本园当前现状写实（限600字以内）", answer: "" },
      { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
      { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
    ]
  },
  {
    id: 14,
    title: "评估指标14.教师队伍之专业发展：注重教师专业能力建设，提高园长专业领导力。",
    guidance: "自评要点：",
    points: [
      "举证说明教师专业发展规划的制订与落实；",
      "说明园本教研制度、教研活动内容和频率；",
      "统计过去一整年里教师平均培训学时、教师培训经费占年度公用经费的百分比；",
      "活命本园教师每年去其它幼儿园观摩的人次；",
      "举证说明幼儿园对教师专业发展的支持措施。",
      "举证说明园长参与教研活动的次数、时间、内容、形式及成效。"
    ],
    wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
    questions: [
      { questionText: "本园当前现状写实（限600字以内）", answer: "" },
      { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
      { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
    ]
  },
  {
    id: 15,
    title: "评估指标15.教师队伍之激励机制：采取有效措施激励教师爱岗敬业、潜心育人。",
    guidance: "自评要点：",
    points: [
      "说明对教师进行激励的方式、措施；",
      "举例说明教职工感受到的关心与支持；",
      "说明各层级教师的大约年均总收入；",
      "说明各类教职工的社保缴费情况；",
      "说明幼儿园各种表彰奖项的设置、数量、频次。"
    ],
    wordLimit: "（请结合以上要点逐项填写，特别注意要具体的举证写实而不要抽象的概括评价，总字数限1000字内）",
    questions: [
      { questionText: "本园当前现状写实（限600字以内）", answer: "" },
      { questionText: "此项指标较前一年度的进步之处（限200字以内）", answer: "" },
      { questionText: "目前存在的问题及未来改进计划（限200字以内）", answer: "" }
    ]
  },

];

const TriangleIcon = styled.span`
  display: inline-block;
  cursor: pointer;
  transform: ${props => props.expanded ? 'rotate(0deg)' : 'rotate(-90deg)'};
  transition: transform 0.2s;
  &:before {
    content: '▼';
  }

  /* 使用 props.expanded 来确定是否旋转 */
  transform: ${props => props.expanded ? 'rotate(0deg)' : 'rotate(-90deg)'};
`;

const CollapsibleContent = styled.div`
  display: ${props => (props.expanded ? 'block' : 'none')};
  transition: display 0.3s ease;
`;


const FloatingWindow = styled.div`
  position: fixed;
  right: 20px;
  top: 80px;
  background: white;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 1000;
  width: 180px;
`;



// 假设gradeOptions是评分选项
const gradeOptions = ['A+', 'A', 'B+', 'B', 'B-', 'C', 'C-'];
const gradeToNumber = grade => {
  switch (grade) {
    case 'A+': return 1;
    case 'A': return 2;
    case 'B+': return 3;
    case 'B': return 4;
    case 'B-': return 5;
    case 'C': return 6;
    case 'C-': return 7;
    default: return 8; // 未评分或其他情况
  }
};





const ExpertReviewPage = () => {
  const [topics, setTopics] = useState([]); // 专家可评分的题目列表
  const [currentTopic, setCurrentTopic] = useState(''); // 当前选中的题目
  const [kindergartens, setKindergartens] = useState([]); // 幼儿园列表及其答案
  const location = useLocation(); // 使用useLocation钩子
  const navigate = useNavigate(); // 创建导航函数
  const [grades, setGrades] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState('');
  const [quantitativeGrades, setQuantitativeGrades] = useState({});
  const [reviewRound, setReviewRound] = useState(1); // 新状态，默认是第一轮
  const [expandedKindergartens, setExpandedKindergartens] = useState({});
  const [distributionData, setDistributionData] = useState({ suggested: {}, actual: {} });
  const [currentPdf, setCurrentPdf] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [evaluatorType, setEvaluatorType] = useState('');

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // 定量评分的选项
  const quantitativeGradeOptions = [-1, 0, 1, 2];
  const displayedGradeOptions = reviewRound === 1 ? ['A', 'B', 'C'] : gradeOptions;

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 51 }, (_, index) => currentYear + index);
    return years;
  };

  const openModalWithImage = (imageUrl) => {
    setCurrentImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModalOrDocument = (attachmentName) => {
    const fileName = attachmentName.split('/').pop();
    const fileExtension = fileName.split('.').pop().toLowerCase();

    const docTypes = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'];
    const videoTypes = ['mp4', 'webm'];
    const pdfType = 'pdf';

    if (docTypes.includes(fileExtension)) {
      const documentUrl = `https://view.officeapps.live.com/op/view.aspx?src=https://baojiaosystem.oss-cn-hangzhou.aliyuncs.com/${encodeURIComponent(attachmentName)}`;
      window.open(documentUrl, '_blank');
    } else if (videoTypes.includes(fileExtension)) {
      // 如果是视频，则设置当前视频URL并打开模态框
      setCurrentVideo(`https://baojiaosystem.oss-cn-hangzhou.aliyuncs.com/${attachmentName}`);
      setIsVideoModalOpen(true); // 假设你有状态isVideoModalOpen来控制视频模态框的显示
    } else if (fileExtension === pdfType) {
      setPageNumber(1)
      // 设置PDF文件路径
      const pdfUrl = `https://baojiaosystem.oss-cn-hangzhou.aliyuncs.com/${attachmentName}`;
      setCurrentPdf(pdfUrl);
    } else {
      // 如果是图片，打开图片预览
      const imageUrl = `https://baojiaosystem.oss-cn-hangzhou.aliyuncs.com/${attachmentName}`;
      openModalWithImage(imageUrl);
    }
  };




  const yearOptions = generateYearOptions();
  const [selectedYear, setSelectedYear] = useState('');
  const currentEvaluation = evaluationItems.find(item => item.id === parseInt(currentTopic, 10));

  const handleGrade = (kgId, topicId, grade) => {
    setGrades(prevGrades => ({
      ...prevGrades,
      [kgId]: {
        ...prevGrades[kgId],
        [topicId]: grade,
      },
    }));
  };

  // 定量评分的输入控件的handleChange事件处理函数
  const handleQuantitativeGradeChange = (kgId, topicId, value) => {
    // 确保输入的值在-1到2的范围内
    const newValue = Math.max(-1, Math.min(value, 2));
    setQuantitativeGrades(prevGrades => ({
      ...prevGrades,
      [kgId]: {
        ...prevGrades[kgId],
        [topicId]: newValue
      }
    }));
  };

  const allKindergartensRated = () => {
    return kindergartens.every(kg => grades[kg.kindergartenId] && grades[kg.kindergartenId][currentTopic]);
  };

  const checkForMissingQuantitativeGrades = () => {
    // 筛选出没有数字评分的幼儿园
    const missingGradesKindergartens = kindergartens.filter(kg => {
      const grades = quantitativeGrades[kg.kindergartenId];
      
      // 如果当前幼儿园的quantitativeGrades不存在或为空对象，则认为评分不完整
      if (!grades || Object.keys(grades).length !== topics.length) {
        return true;
      }
  
      // 检查每个评分项中的所有指标是否都已评分
      for (let key in grades) {
        if (grades[key] === null || grades[key] === undefined || grades[key] === ''|| isNaN(grades[key])) {
          return true;
        }
      }
  
      return false;
    });
  
    
    return missingGradesKindergartens.map(kg => kg.kindergartenName);
  };
  



  const fetchSuggestedDistribution = async (round) => {
    try {
      const response = await api.getDistributionData();
      return round === 1 ? response.data.data.firstRoundDistribution : response.data.data.secondRoundDistribution;

    } catch (error) {
      console.error("Error fetching suggested distribution: ", error);
      return {};
    }
  };



  const updateDistributionData = async () => {
    const totalKindergartens = kindergartens.length;
    const suggestedDistribution = await fetchSuggestedDistribution(reviewRound);
    const actualCounts = {};
    const currentGrades = grades[currentTopic] || {};

    // 计算实际的评分分布
    Object.keys(grades).forEach(kgId => {
      const gradeForCurrentTopic = grades[kgId][currentTopic];
      if (gradeForCurrentTopic) {
        actualCounts[gradeForCurrentTopic] = (actualCounts[gradeForCurrentTopic] || 0) + 1;
      }
    });

    setDistributionData({ suggested: suggestedDistribution, actual: actualCounts });
  };






  const renderDistributionWindow = () => {
    const { suggested, actual } = distributionData; // 使用已经计算好的分布数据
    const gradeOrder = reviewRound === 1 ? ['A', 'B', 'C'] : ['A+', 'A', 'B+', 'B', 'B-', 'C', 'C-']; // 为第二轮设置顺序

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 为了更平滑的滚动效果
      });
    };

    return (
      <FloatingWindow>

        <h4>{reviewRound === 1 ? '第一轮分布' : '第二轮分布'}</h4>
        <div>
          {gradeOrder.map(key => (
            <div key={key} style={actual[key] > suggested[key] ? { color: 'red' } : null}>
              {key}: 建议 {suggested[key]}, 实际 {actual[key] || 0}
            </div>
          ))}
        </div>
        <button onClick={scrollToTop} style={{ marginTop: '10px' }}>回到顶部</button>
        <button onClick={handleLogout} style={{ marginTop: '10px' }}>登出</button> {/* 新增登出按钮 */}
        <div>评估人员类型: {evaluatorType}</div> {/* 显示评估人员类型 */}
      </FloatingWindow>
    );
  };




  useEffect(() => {
    const initializeData = async () => {
      await updateDistributionData();
    };
    initializeData();
  }, [currentTopic, grades, reviewRound, kindergartens.length]);



  useEffect(() => {
    const fetchKindergartensData = async () => {
      let { expertId } = location.state || {};
      if (!expertId || !selectedYear) return;

      try {
        const topicsResponse = await api.getExpertById(expertId);
        setEvaluatorType(topicsResponse.data.data.evaluatorType);
        console.log(topicsResponse.data.data.evaluatorType);
        setTopics(topicsResponse.data.data.selectedNumbers);
        if (topicsResponse.data.data.selectedNumbers.length > 0) {
          // 调用 API，并假定API会返回一个对象，其中包含数据数组
          const kindergartensResponse = await api.getEvaluationsByYear(selectedYear);

          // 确保响应数据中有data属性，并且它是一个数组
          if (Array.isArray(kindergartensResponse.data.data)) {
            setKindergartens(kindergartensResponse.data.data);

            // 初始化所有幼儿园的折叠状态为展开
            setExpandedKindergartens(
              kindergartensResponse.data.data.reduce((acc, current) => {
                acc[current.kindergartenId] = true; // 设置为true来默认展开所有行
                return acc;
              }, {})
            );
          } else {
            // 如果不是数组，设置为空数组以避免错误
            setKindergartens([]);
          }



          const gradesResponse = await api.getExpertGradesById(expertId, selectedYear);
          if (gradesResponse.data.data) {
            setGrades(gradesResponse.data.data.grades.grades);
            setQuantitativeGrades(gradesResponse.data.data.grades.quantitativeGrades);

            if (Object.keys(gradesResponse.data.data.grades.quantitativeGrades).length > 0) {
              // 如果有评分数据，默认显示第二轮评测
              setReviewRound(2);
            } else {
              // 如果没有评分数据，默认显示第一轮评测
              setReviewRound(1);
            }
            // 如果有评分数据，将其应用于幼儿园列表
            setKindergartens(prevKindergartens => {
              return prevKindergartens.map(kg => {
                const gradeData = gradesResponse.data.data.grades[kg.kindergartenId];
                const quantitativeGradeData = gradesResponse.data.data.quantitativeGrades[kg.kindergartenId];
                return {
                  ...kg,
                  evaluationData: {
                    ...kg.evaluationData,
                    grades: gradeData,
                    quantitativeGrades: quantitativeGradeData,
                  },
                };
              });
            });



          } else {
            // 如果没有评分数据，设置默认值
            setGrades({});
            setQuantitativeGrades({});

            // 将默认值应用于幼儿园列表
            setKindergartens(prevKindergartens => {
              return prevKindergartens.map(kg => {
                return {
                  ...kg,
                  evaluationData: {
                    ...kg.evaluationData,
                    grades: {},
                    quantitativeGrades: {},
                  },
                };
              });
            });
          }

        }
      } catch (error) {
        if (error.response.data.error === "Expert not found") {

        } else {
          toast.error("获取数据失败");
        }
      }
    };

    fetchKindergartensData();
  }, [selectedYear, location.state]);

  useEffect(() => {
    if (currentTopic) {
      sortKindergartensByGrades();
    }
  }, [currentTopic]);


  const toggleKindergarten = (id) => {
    setExpandedKindergartens(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const handleSelectTopic = (topicNumber) => {
    setCurrentTopic(topicNumber);
  };

  const handleSaveGrades = async () => {
    // 检查缺失的数字评分
    const missingQuantitativeGrades = checkForMissingQuantitativeGrades();

    // 如果有缺失的评分，则弹窗提醒
    if (missingQuantitativeGrades.length > 0) {
      const continueSave = window.confirm(`以下幼儿园的数字评分未完成，是否继续保存？\n\n${missingQuantitativeGrades.join('\n')}`);
      if (!continueSave) {
        // 如果用户选择不继续，则退出函数
        return;
      }
    }

    // 假设expertId从某处获取，比如URL参数或状态管理库
    const expertId = location.state?.expertId;

    // 构造要保存的数据结构
    const playload = {
      expertId: expertId,
      grades: grades, // grades是当前所有幼儿园的评分数据
      quantitativeGrades: quantitativeGrades,
      year: selectedYear,
    };

    try {
      // 调用API保存数据
      const response = await api.saveExpertGrades(playload);
      toast.success("保存成功！");
    } catch (error) {
      console.error("保存失败：", error);
      toast.error("保存失败！");
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(kindergartens);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setKindergartens(items);
  };

  const sortKindergartensByGrades = () => {
    
    // 创建一个新的数组以避免直接修改状态
    const sorted = [...kindergartens].sort((a, b) => {
      // 获取两个幼儿园的评分
      const gradeA = grades[a.kindergartenId] ? gradeToNumber(grades[a.kindergartenId][currentTopic]) : 4;
      const gradeB = grades[b.kindergartenId] ? gradeToNumber(grades[b.kindergartenId][currentTopic]) : 4;

      return gradeA - gradeB;
    });
    
    // 更新幼儿园列表的状态
    setKindergartens(sorted);
  };

  const isSelectionComplete = selectedYear && currentTopic;

  const handleLogout = async () => {
    const confirmLogout = window.confirm("您确定要登出吗？");
    if (confirmLogout) {
      try {
        // 假设 `api.logout` 是执行登出操作的API
        navigate('/login');
        // 登出成功后的操作，如重定向到登录页
        // 例如：window.location.href = '/login';
      } catch (error) {
        console.error("登出失败：", error);
        toast.error("登出失败！");
      }
    }
  };


  return (
    <Container>
      {renderDistributionWindow()}

      <ToastContainer />
      <Select onChange={e => setSelectedYear(e.target.value)} value={selectedYear}>
        <option value="">请选择年份</option>
        {yearOptions.map(year => (
          <option key={year} value={year}>{year}</option>
        ))}
      </Select>

      <Select onChange={e => handleSelectTopic(e.target.value)} value={currentTopic}>
        <option value="">请选择指标</option> {/* 添加“请选择主题”选项 */}
        {topics.map(topic => (
          <option key={topic} value={topic}>{topic}</option>
        ))}
      </Select>

      <div style={{ margin: '20px 0' }}>
        <Button
          className={reviewRound === 1 ? 'highlight' : ''}
          onClick={() => setReviewRound(1)}
        >
          第一轮
        </Button>
        <Button
          className={reviewRound === 2 ? 'highlight' : ''}
          onClick={() => {
            setReviewRound(2);
            sortKindergartensByGrades(); // 排序幼儿园列表
          }}
          disabled={!allKindergartensRated()} // 如果没有所有幼儿园都已评分，则禁用按钮
          style={{ opacity: allKindergartensRated() ? 1 : 0.5 }} // 如果没有所有幼儿园都已评分，减少按钮的透明度
        >
          第二轮
        </Button>

        <button onClick={sortKindergartensByGrades}>重新排序</button>
        <button onClick={handleSaveGrades}>提交本指标打分</button>
      </div>
      {currentEvaluation && (
        <>
          <EvaluationTitle>{currentEvaluation.title}</EvaluationTitle>
          <EvaluationGuidance>{currentEvaluation.guidance}</EvaluationGuidance>
          <ul>
            {currentEvaluation.points.map((point, index) => (
              <EvaluationPoint key={index}>{point}</EvaluationPoint>
            ))}
          </ul>
        </>
      )}
      {isSelectionComplete ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Table>
            <Droppable droppableId="droppable">
              {(provided) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {kindergartens.map((kg, index) => {
                    // Make sure to define `answer` within the scope of this map callback
                    const isExpanded = expandedKindergartens[kg.kindergartenId];
                    const answer = kg.evaluationData.dimensions[currentTopic - 1] || { questions: [], attachments: [] };

                    return (
                      <Draggable key={kg.kindergartenId} draggableId={kg.kindergartenId} index={index}>
                        {(provided) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TableCell>
                              <TriangleIcon
                                onClick={() => toggleKindergarten(kg.kindergartenId)}
                                expanded={isExpanded}
                              />
                              {kg.kindergartenName}
                            </TableCell>
                            <TableCell>
                              {displayedGradeOptions.map((gradeOption) => (
                                <GradeCircle
                                  key={gradeOption}
                                  onClick={() => handleGrade(kg.kindergartenId, currentTopic, gradeOption)}
                                  selected={gradeOption === (grades[kg.kindergartenId] && grades[kg.kindergartenId][currentTopic])}
                                >
                                  {gradeOption}
                                </GradeCircle>

                              ))}
                              {reviewRound == 2 &&
                                <div>
                                  <span>评分：</span>
                                  <input
                                    type="number"
                                    min="-1"
                                    max="2"
                                    step="1"
                                    value={quantitativeGrades[kg.kindergartenId]?.[currentTopic] === undefined ? '' : quantitativeGrades[kg.kindergartenId][currentTopic]}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      handleQuantitativeGradeChange(kg.kindergartenId, currentTopic, value === '' ? undefined : parseInt(value, 10));
                                    }}
                                  />
                                </div>}


                            </TableCell>

                            <TableCell>
                              <CollapsibleContent expanded={isExpanded}>
                                {currentEvaluation && currentEvaluation.questions.map((question, qIndex) => (
                                  <AnswerContainer key={qIndex}>
                                    <EvaluationQuestion>{question.questionText}</EvaluationQuestion>
                                    {answer.questions[qIndex]?.answer || '未填写'}
                                  </AnswerContainer>
                                ))}
                                {/* 附件列表 */}
                                <AttachmentList>
                                  {answer.attachments.map((attachment, aIndex) => {
                                    // 分割URL并获取完整路径
                                    const fullUrl = `https://baojiaosystem.oss-cn-hangzhou.aliyuncs.com/${attachment.name}`;
                                    // 只显示文件名部分
                                    const fileName = attachment.name.split('/').pop();

                                    return (
                                      <AttachmentListItem
                                        key={aIndex}
                                        onClick={() => openModalOrDocument(attachment.name)}
                                        style={{ cursor: 'pointer' }} // 鼠标悬停时变为小手形状
                                      >
                                        {fileName}
                                      </AttachmentListItem>
                                    );
                                  })}
                                </AttachmentList>


                                {/* 如果没有附件，显示"无附件" */}
                                {answer.attachments.length === 0 && <AttachmentListItem>无附件</AttachmentListItem>}
                              </CollapsibleContent>
                            </TableCell>



                          </TableRow >
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
      ) : (
        // 如果没有选择年份和题目，显示提示信息
        <div>请选择年份和题目以查看表格。</div>
      )}
      {isModalOpen && (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1050 }}>
          <img src={currentImage} alt="Preview" style={{ maxWidth: '90vw', maxHeight: '90vh' }} />
          <button onClick={closeModal}>关闭图片</button>
        </div>
      )}
      {isVideoModalOpen && (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1050, backgroundColor: 'white', padding: '20px' }}>
          <video src={currentVideo} controls style={{ maxWidth: '90vw', maxHeight: '90vh' }}></video>
          <button onClick={() => setIsVideoModalOpen(false)}>关闭视频</button>
        </div>
      )}
      {currentPdf && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1050,
          overflow: 'auto',
          backgroundColor: 'white',
          maxHeight: '80vh',
          maxWidth: '80vw',
          width: 'auto',
          height: 'auto',
          padding: '10px'
        }}>
          <Document
            file={currentPdf}
            onLoadSuccess={onDocumentLoadSuccess}

          >
            <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} width={1200} scale={1} />
          </Document>
          <div style={{ textAlign: 'center' }}>
            <p>Page {pageNumber} of {numPages}</p>
            <button onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber <= 1}>
              上一页
            </button>
            <button onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber >= numPages}>
              下一页
            </button>
            <button onClick={() => setCurrentPdf(null)} style={{
              backgroundColor: '#f0f0f0',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '5px',
              marginTop: '10px'
            }}>关闭</button>
          </div>
        </div>
      )}






    </Container>
  );
};

export default ExpertReviewPage;
