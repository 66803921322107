import React, { useState, useEffect } from 'react';

const LoadingModal = ({ isLoading }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (isLoading) {
      setSeconds(0); // 每次当isLoading变为true时重置秒数
      const interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds + 1);
      }, 1000);
      return () => clearInterval(interval); // 组件卸载时清除计时器
    }
    // 如果不需要在isLoading为false时做任何操作，则可以省略下面的else部分
    else {
      setSeconds(0); // 也可以在这里重置，以确保每次关闭模态窗口时秒数归零
    }
  }, [isLoading]); // 依赖于isLoading，这样每次isLoading变化时都会重新设置计时器

  // 将秒数转换为小时:分钟:秒格式
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const secs = totalSeconds - hours * 3600 - minutes * 60;

    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(secs).padStart(2, '0');

    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  };

  if (!isLoading) return null;

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
      <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', textAlign: 'center' }}>
        <h2>正在上传，请不要关闭此页面...</h2>
        <p>已上传时间：{formatTime(seconds)}</p>
      </div>
    </div>
  );
};

export default LoadingModal;
