import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../../api";

const Container = styled.div`
  padding: 20px;
  border: 2px dashed #ccc;
`;

const Title = styled.h2`
  text-align: center;
`;

const SubTitle = styled.h3`
  margin-top: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckboxGroup = styled.div`
  flex: 1;
  padding: 10px;
`;

const CheckboxLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-top: 10px;
`;

const GradeContainer = styled.div`
  margin-top: 20px;
`;

const GradeTitle = styled.p`
  margin-bottom: 10px;
`;

const GradeSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Evaluation31 = ({ onSurveyUpdate }) => {
    const location = useLocation();
    const [initialFormData, setInitialFormData] = useState(null);
    const [formData, setFormData] = useState({
        negative: Array(14).fill(0), // 假设有14个消极表现
        positive: Array(14).fill(0), // 假设有14个积极表现
        additionalComments1: '',
        additionalComments2: '',
        grade: '',
        surveyNumber: 31, // 添加问卷序号
        kindergartenId: '', // 添加幼儿园ID
        year: new Date().getFullYear() // 添加年份
    });
    const [currentExpertId, setCurrentExpertId] = useState('');
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const kindergartenId = params.get('kindergartenId');
        const expertId = params.get('expertId');
        setCurrentExpertId(expertId);

        setFormData(prevState => ({
            ...prevState,
            kindergartenId
        }));

        const fetchData = async () => {
            try {
                const response = await api.getExpertGrades2(expertId, formData.year, kindergartenId, formData.surveyNumber);
                if (response.data.success && response.data.data) {
                    setFormData(prevState => ({
                        ...prevState,
                        ...response.data.data
                    }));
                    setInitialFormData(response.data.data);
                } else {
                    setInitialFormData(formData);
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    // 处理404错误，保持表单为空
                    setInitialFormData(formData);
                } else {
                    console.error('Error fetching data:', error);
                }
            } finally {
                setIsDataLoaded(true);
            }
        };

        fetchData();
    }, [location]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (JSON.stringify(initialFormData) !== JSON.stringify(formData)) {
                const message = 'You have unsaved changes, do you really want to leave?';
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [initialFormData, formData]);

    const handleCheckboxChange = (e, type, index) => {
        const { checked } = e.target;
        setFormData(prevState => {
            const updatedList = [...prevState[type]];
            updatedList[index] = checked ? 1 : 0;

            return {
                ...prevState,
                [type]: updatedList
            };
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: name === 'grade' ? Number(value) : value // 将grade转换为数字
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault(); 
        
        if (formData.grade === 0||formData.grade === '') {
            toast.error("您还没选择评级，请选择评级。");
            return;
        }

        const playload = {
            expertId: currentExpertId,
            formData: formData,
        };

        // 提交表单数据
        api.saveExpertGrades2(playload)
            .then(() => {
                // 更新 initialFormData
                setInitialFormData(formData);
                toast.success("保存成功");
                // 调用父组件的更新函数
                onSurveyUpdate(formData.surveyNumber);
            })
            .catch(error => {
                console.error('Error saving data:', error);
                toast.error("保存失败，请重试");
            });
    };

    if (!isDataLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <Container>
            <ToastContainer />
            <Title>考查要点 3.1</Title>
            <SubTitle>消极表现</SubTitle>
            <CheckboxContainer>
                <CheckboxGroup>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 0)}
                            checked={formData.negative[0] === 1}
                        />
                        教师组织的教育活动存在“拔苗助长”、囿于幼儿同水平重复等问题。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 1)}
                            checked={formData.negative[1] === 1}
                        />
                        违背幼儿发展阶段性特征，将一套教学方式应用于所有年龄段的幼儿。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 2)}
                            checked={formData.negative[2] === 1}
                        />
                        教师难以捕捉幼儿发展的关键期，错失最佳的教育时机。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 3)}
                            checked={formData.negative[3] === 1}
                        />
                        违背《规程》《纲要》《指南》中强调的幼儿为本思想。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 4)}
                            checked={formData.negative[4] === 1}
                        />
                        课程方案和一日活动安排以教师为主。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 5)}
                            checked={formData.negative[5] === 1}
                        />
                        活动课程方案拿来就用，不考虑本班幼儿实际情况。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 6)}
                            checked={formData.negative[6] === 1}
                        />
                        活动过程中不能捕捉到幼儿需求，难以根据幼儿需求适当调整活动内容。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 7)}
                            checked={formData.negative[7] === 1}
                        />
                        教育过程中忽视幼儿的个性和差异性，常常“一刀切”。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 8)}
                            checked={formData.negative[8] === 1}
                        />
                        用同一个标准去评价每一个幼儿。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 9)}
                            checked={formData.negative[9] === 1}
                        />
                        一日活动难以保证合理的游戏活动时间。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 10)}
                            checked={formData.negative[10] === 1}
                        />
                        一日活动安排难以体现动静交替、室内外相结合的特征。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 11)}
                            checked={formData.negative[11] === 1}
                        />
                        游戏所需材料匮乏，不能满足幼儿游戏需求。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 12)}
                            checked={formData.negative[12] === 1}
                        />
                        游戏活动安排刻板，趋于形式化，缺乏一定的灵活性。
                    </CheckboxLabel>          
                </CheckboxGroup>        
            </CheckboxContainer>

            <Textarea
                name="additionalComments1"
                value={formData.additionalComments1}
                onChange={handleChange}
                placeholder="如有其他证据，请写在下方："
            />

            <SubTitle>积极表现</SubTitle>
            <CheckboxContainer>
                <CheckboxGroup>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 0)}
                            checked={formData.positive[0] === 1}
                        />
                        遵循幼儿发展特点，活动内容围绕由简单到复杂的顺序进行，教师在活动中能够做到循序渐进。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 1)}
                            checked={formData.positive[1] === 1}
                        />
                        不同幼儿的发展速度和水平存在差异，能够为幼儿提供恰当的发展条件。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 2)}
                            checked={formData.positive[2] === 1}
                        />
                        活动安排有序，能给每个幼儿提供丰富多彩的活动机会和互动体验。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 3)}
                            checked={formData.positive[3] === 1}
                        />
                        体现《规程》《纲要》《指南》强调的幼儿为本思想。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 4)}
                            checked={formData.positive[4] === 1}
                        />
                        给幼儿提供活动安排的机会，可以根据幼儿需求灵活调整活动内容。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 5)}
                            checked={formData.positive[5] === 1}
                        />
                        关注每个幼儿的情绪情感，对不同个性的幼儿采取相应的引导方式。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 6)}
                            checked={formData.positive[6] === 1}
                        />
                        了解幼儿的生理差异，关注其在身高体型和身体发育方面。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 7)}
                            checked={formData.positive[7] === 1}
                        />
                        能够保证幼儿有充足的游戏时间。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 8)}
                            checked={formData.positive[8] === 1}
                        />
                        游戏中关注到不同年龄段幼儿在游戏和生活活动中的差异和需求。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 9)}
                            checked={formData.positive[9] === 1}
                        />
                        游戏活动安排合理，能够根据地域、季节特征，设计适宜的一日活动。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 10)}
                            checked={formData.positive[10] === 1}
                        />
                        鼓励和支持幼儿自主解决游戏中出现的问题，能够在游戏活动中获得生活常识和道德规范。
                    </CheckboxLabel>          
                </CheckboxGroup>
            </CheckboxContainer>

            <Textarea
                name="additionalComments2"
                value={formData.additionalComments2}
                onChange={handleChange}
                placeholder="如有其他证据，请写在下方："
            />

            <GradeContainer>
                <GradeTitle>请根据上述表现对该考查要点进行评级：</GradeTitle>
                <GradeSelect
                    name="grade"

                    value={formData.grade}
                    onChange={handleChange}
                >
                    <option value="">请选择评级</option>
                    <option value={1}>优秀</option>
                    <option value={2}>良好</option>
                    <option value={3}>及格</option>
                    <option value={4}>不及格</option>
                    <option value={5}>不了解</option>
                </GradeSelect>
            </GradeContainer>


            <Button onClick={handleSubmit}>保存</Button>
        </Container>
    );
};

export default Evaluation31;
