import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom"; // 导入useLocation
import api from '../api'; // 假设你有一个API文件来处理HTTP请求
import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Button = styled.button`
  padding: 20px 40px;
  margin: 20px;
  font-size: 24px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ disabled }) => (disabled ? '#ccc' : '#4CAF50')};
  color: white;
  border: none;
  border-radius: 10px;
  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#ccc' : '#45a049')};
  }
`;

const ExpertLoginDashboard = () => {
  const [permissions, setPermissions] = useState({ onlineEvaluation: false, onSiteEvaluation: false });
  const location = useLocation(); // 使用useLocation钩子
  const [assignedKindergartens, setAssignedKindergartens] = useState([]);
  const [topics, setTopics] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let { expertId } = location.state || {};
    const fetchPermissions = async () => {
      try {
        const response = await api.getExpertSettings2(expertId);
        setAssignedKindergartens(response.data.data);

        const topicsResponse = await api.getExpertById(expertId);
        console.log(topicsResponse.data.data.selectedNumbers);
        setTopics(topicsResponse.data.data.selectedNumbers);

      } catch (error) {
        console.error('Failed to fetch expert permissions:', error);
      }
    };

    fetchPermissions();
  }, []);

  return (
    <Container>
      <Button
        disabled={topics.length === 0}
        onClick={() => navigate('/ExpertReviewPage', {
          state: {
            expertId: location.state.expertId,
            realName: location.state.realName,
          }
        })}
      >
        专家在线评分 on 幼儿园自评资料
      </Button>
      <Button
        // disabled={assignedKindergartens.length === 0}
        disabled={true}
        onClick={() => navigate('/on-site-evaluation',{
          state: {
            expertId: location.state.expertId,
            realName: location.state.realName,
            assignedKindergartens: assignedKindergartens,
          }
        })}
      >
        专家在线评分 on 入园打分
      </Button>
    </Container>
  );
};

export default ExpertLoginDashboard;
