import React, { useState, useEffect } from "react";
import styled from "styled-components";

// 样式组件
const Container = styled.div`
  position: relative;
  font-family: sans-serif;
`;

const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 12px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  
  &:hover {
    border-color: #40a9ff;
  }
  
  &:focus {
    outline: none;
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;

// 自定义徽章组件
const CustomBadge = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const BadgeCount = styled.span`
  position: absolute;
  top: -8px;
  right: -8px;
  min-width: 16px;
  height: 16px;
  line-height: 16px;
  padding: 0 4px;
  border-radius: 10px;
  background-color: #ff4d4f;
  color: #fff;
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
  transform-origin: 100% 0%;
  transform: ${props => (props.count > 0 ? 'scale(1)' : 'scale(0)')};
  transition: transform 0.2s;
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  padding: 8px 0;
  margin-top: 4px;
`;

const SearchInput = styled.input`
  width: calc(100% - 16px);
  margin: 0 8px 8px 8px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  
  &:focus {
    border-color: #40a9ff;
    outline: none;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;

const CheckboxContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
  padding: 0 8px;
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxItem = styled.div`
  margin: 4px 0;
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled.label`
  margin-left: 8px;
  cursor: pointer;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  border-top: 1px solid #f0f0f0;
  margin-top: 8px;
`;

const ActionButton = styled.button`
  margin-left: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${props => props.primary ? '#1890ff' : '#fff'};
  color: ${props => props.primary ? '#fff' : '#1890ff'};
  border: 1px solid ${props => props.primary ? '#1890ff' : '#d9d9d9'};
  
  &:hover {
    background-color: ${props => props.primary ? '#40a9ff' : '#f0f0f0'};
  }
`;

// 自定义复选框组件
const CustomCheckbox = ({ checked, onChange, indeterminate, children }) => {
  return (
    <CheckboxItem>
      <input 
        type="checkbox" 
        checked={checked} 
        onChange={onChange}
        style={{ 
          cursor: 'pointer',
          accentColor: '#1890ff'
        }}
      />
      <CheckboxLabel>{children}</CheckboxLabel>
    </CheckboxItem>
  );
};

/**
 * 多选下拉框组件
 * @param {Array} options - 选项列表
 * @param {Array} selectedOptions - 已选中的选项
 * @param {Function} onChange - 选项变化时的回调函数
 * @param {String} placeholder - 按钮上显示的文字
 */
const DropdownCheckbox = ({ 
  options = [], 
  selectedOptions = [], 
  onChange,
  placeholder = "选择选项" 
}) => {
  // 状态
  const [isOpen, setIsOpen] = useState(false);
  const [checkedList, setCheckedList] = useState(selectedOptions);
  const [checkAll, setCheckAll] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  // 当外部selectedOptions变化时更新内部状态
  useEffect(() => {
    setCheckedList(selectedOptions);
    updateCheckAllState(selectedOptions);
  }, [selectedOptions, options]);

  // 当搜索文本或选项变化时，更新过滤后的选项
  useEffect(() => {
    if (searchText) {
      const filtered = options.filter(option => 
        option.toString().toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  }, [searchText, options]);

  // 更新全选状态
  const updateCheckAllState = (list) => {
    setCheckAll(list.length > 0 && list.length === options.length);
  };

  // 处理选项变化
  const handleChange = (option) => {
    let newCheckedList;
    if (checkedList.includes(option)) {
      newCheckedList = checkedList.filter(item => item !== option);
    } else {
      newCheckedList = [...checkedList, option];
    }
    setCheckedList(newCheckedList);
    updateCheckAllState(newCheckedList);
  };

  // 处理全选变化
  const handleCheckAllChange = (e) => {
    const checked = e.target.checked;
    const newCheckedList = checked ? [...options] : [];
    
    setCheckedList(newCheckedList);
    setCheckAll(checked);
  };

  // 切换下拉框显示状态
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // 处理确认按钮点击
  const handleOk = () => {
    if (onChange) {      
      onChange(checkedList);
    }
    setIsOpen(false);
  };

  // 处理取消按钮点击
  const handleCancel = () => {
    setCheckedList(selectedOptions);
    updateCheckAllState(selectedOptions);
    setIsOpen(false);
  };

  // 处理搜索文本变化
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <Container>
      <CustomBadge>
        <DropdownButton onClick={toggleDropdown}>
          {placeholder}
        </DropdownButton>
        <BadgeCount count={checkedList.length}>
          {checkedList.length > 0 ? checkedList.length : ''}
        </BadgeCount>
      </CustomBadge>
      
      {isOpen && (
        <DropdownContent>
          <SearchInput
            placeholder="搜索..."
            value={searchText}
            onChange={handleSearchChange}
          />
          
          <CheckboxContainer>
            <CustomCheckbox
              checked={checkAll}
              onChange={handleCheckAllChange}
            >
              全选
            </CustomCheckbox>
            
            <CheckboxGroup>
              {filteredOptions.map((option) => (
                <CustomCheckbox
                  key={option}
                  checked={checkedList.includes(option)}
                  onChange={() => handleChange(option)}
                >
                  {option}
                </CustomCheckbox>
              ))}
            </CheckboxGroup>
          </CheckboxContainer>
          
          <ButtonGroup>
            <ActionButton onClick={handleCancel}>
              取消
            </ActionButton>
            <ActionButton primary onClick={handleOk}>
              确定
            </ActionButton>
          </ButtonGroup>
        </DropdownContent>
      )}
    </Container>
  );
};

export default DropdownCheckbox;
