import React, { useState } from 'react';
import styled from 'styled-components';
import api from '../api'; // 假设你有一个用于与后端通信的API库
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px; /* 控制输入框之间的间距 */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Input = styled.input`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const Button = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
`;

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { token } = useParams(); // 假设你使用react-router并且路由定义为"/reset-password/:token"
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            toast.error("密码和确认密码不匹配");
            return;
        }

        try {
            // 发送请求到服务器端重置密码
            // API调用方法应该根据你的后端实现进行适配
            await api.resetPassword({ token, password });    
            alert("密码重置成功，请使用新密码登录");
            navigate('/login');       
            toast.success("密码重置成功，请使用新密码登录",{ autoClose: 500 });
            // 导航到登录页面或其他操作
        } catch (error) {
            toast.error("密码重置失败",{ autoClose: 500 });
            console.error("Reset password error:", error);
        }
    };

    return (
        <FormContainer>
            <StyledForm onSubmit={handleSubmit}>
                <h2>重置密码</h2>
                <Input 
                    type="password"
                    placeholder="新密码"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <Input 
                    type="password"
                    placeholder="确认新密码"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                <Button type="submit">重置密码</Button>
            </StyledForm>
        </FormContainer>
    );
};

export default ResetPassword;
