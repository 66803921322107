import LoginView from "./login";
import Register from "./register";
import Account from "./account";
import EvaluationForm from "./EvaluationForm";
import Dashboard from "./dashboard";
import KinderGardenPage from "./KinderGardenPage";
import ExpertsPage from "./ExpertsPage";
import SettingsPage from "./SettingsPage";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import ExpertRegistration from "./ExpertRegistration";
import ExpertReviewPage from "./ExpertReviewPage";
import SecondStageSettingsPage from "./SecondStageSettingsPage";
import ExpertLoginDashboard from "./ExpertLoginDashboard";
import OnSiteEvaluationPage from "./OnSiteEvaluationPage";
import SubKindergardenReport from "./reports/SubKindergardenReport";
export {  LoginView,Register,Account,EvaluationForm,Dashboard,KinderGardenPage,
    ExpertsPage,SettingsPage,ForgotPassword,ResetPassword ,ExpertRegistration,
    ExpertReviewPage,SecondStageSettingsPage,ExpertLoginDashboard ,OnSiteEvaluationPage,SubKindergardenReport } 