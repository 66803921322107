import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom'
// import {  MoviesInsert, MoviesUpdate,LoginView,ReportShow ,Register,Account,ProvinceReport} from '../pages'
import {  LoginView,Register,Account,EvaluationForm,Dashboard,
    KinderGardenPage,UsersPage,SettingsPage,ForgotPassword,ResetPassword,ExpertRegistration,ExpertReviewPage,ExpertLoginDashboard,
OnSiteEvaluationPage,SubKindergardenReport} from '../pages'

import {  NavBar,Footer } from '../components'
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
    return (
        <Router>
            {/* <NavBar /> */}
            <Routes>                
                <Route path="/login" exact element={<LoginView/>}/>
                <Route path="/register" exact element={<Register/>}/>
                <Route path="/ExpertRegistration" exact element={<ExpertRegistration/>}/>
                <Route path="/account" exact element={<Account/>}/>    
                <Route path="/evaluationForm" exact element={<EvaluationForm/>}/> 
                <Route path="/dashboard/*" element={<Dashboard />} />
                <Route path="/forgot-password" exact element={<ForgotPassword/>}/>
                <Route path="/reset-password/:token" element={<ResetPassword/>}/>
                <Route path="/ExpertReviewPage" element={<ExpertReviewPage />} />
                <Route path="/kinderGardenPage" element={<KinderGardenPage />} />
                <Route path="/ExpertLoginDashboard" element={<ExpertLoginDashboard />} />
                <Route path="/on-site-evaluation/*" element={<OnSiteEvaluationPage />} />
                <Route path="/sub-kindergarden-report" element={<SubKindergardenReport />} />
                {/* 默认重定向到登录页面 */}
                <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
            <Footer /> 
        </Router>
    )
}

export default App