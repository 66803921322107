import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../../api";

const Container = styled.div`
  padding: 20px;
  border: 2px dashed #ccc;
  margin: 20px;
`;

const Title = styled.h2`
  text-align: center;
`;

const SubTitle = styled.h3`
  margin-top: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckboxGroup = styled.div`
  flex: 1;
  padding: 10px;
`;

const CheckboxLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-top: 10px;
`;

const GradeContainer = styled.div`
  margin-top: 20px;
`;

const GradeSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Evaluation44 = ({ onSurveyUpdate }) => {
  const location = useLocation();
  const [initialFormData, setInitialFormData] = useState(null);
  const [formData, setFormData] = useState({
    negative: Array(13).fill(0), // 假设有14个消极表现
    positive: Array(13).fill(0), // 假设有13个积极表现
    additionalComments1: '',
    additionalComments2: '',
    grade: '',
    surveyNumber: 44, // 添加问卷序号
    kindergartenId: '', // 添加幼儿园ID
    year: new Date().getFullYear() // 添加年份
  });
  const [currentExpertId, setCurrentExpertId] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const kindergartenId = params.get('kindergartenId');
    const expertId = params.get('expertId');
    setCurrentExpertId(expertId);

    setFormData(prevState => ({
      ...prevState,
      kindergartenId
    }));

    const fetchData = async () => {
      try {
        const response = await api.getExpertGrades2(expertId, formData.year, kindergartenId, formData.surveyNumber);
        if (response.data.success && response.data.data) {
          setFormData(prevState => ({
            ...prevState,
            ...response.data.data
          }));
          setInitialFormData(response.data.data);
        } else {
          setInitialFormData(formData);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setInitialFormData(formData);
        } else {
          console.error('Error fetching data:', error);
        }
      } finally {
        setIsDataLoaded(true);
      }
    };

    fetchData();
  }, [location]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (JSON.stringify(initialFormData) !== JSON.stringify(formData)) {
        const message = 'You have unsaved changes, do you really want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [initialFormData, formData]);

  const handleCheckboxChange = (e, type, index) => {
    const { checked } = e.target;
    setFormData(prevState => {
      const updatedList = [...prevState[type]];
      updatedList[index] = checked ? 1 : 0;

      return {
        ...prevState,
        [type]: updatedList
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'grade' ? Number(value) : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    
    if (formData.grade === 0||formData.grade === '') {
        toast.error("您还没选择评级，请选择评级。");
        return;
    }

    const playload = {
        expertId: currentExpertId,
        formData: formData,
    };

    // 提交表单数据
    api.saveExpertGrades2(playload)
      .then(() => {
        // 更新 initialFormData
        setInitialFormData(formData);
        toast.success("保存成功");
        // 调用父组件的更新函数
        onSurveyUpdate(formData.surveyNumber);
      })
      .catch(error => {
        console.error('Error saving data:', error);
        toast.error("保存失败，请重试");
      });
  };

  if (!isDataLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <ToastContainer />
      <Title>考查要点 4.4</Title>
      <SubTitle>消极表现</SubTitle>
      <CheckboxContainer>
        <CheckboxGroup>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 0)}
              checked={formData.negative[0] === 1}
            />
            未配备专（兼）职卫生保健人员。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 1)}
              checked={formData.negative[1] === 1}
            />
            卫生保健人员比例不达标。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 2)}
              checked={formData.negative[2] === 1}
            />
            卫生保健人员缺乏相关资质证明。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 3)}
              checked={formData.negative[3] === 1}
            />
            保健室未能及时应急药品和相关医疗设施。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 4)}
              checked={formData.negative[4] === 1}
            />
            餐点时间安排不合理。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 5)}
              checked={formData.negative[5] === 1}
            />
            进餐的心理氛围消极。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 6)}
              checked={formData.negative[6] === 1}
            />
            未做到每日晨检、午检。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 7)}
              checked={formData.negative[7] === 1}
            />
            幼儿园卫生条件差，未定期清洁和消毒。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 8)}
              checked={formData.negative[8] === 1}
            />
            未开窗通风。
          </CheckboxLabel>
        </CheckboxGroup>
        <CheckboxGroup>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 9)}
              checked={formData.negative[9] === 1}
            />
            未做到每人每日1巾1杯专用，每人1床位1被。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 10)}
              checked={formData.negative[10] === 1}
            />
            幼儿入园体检率未达到100％，定期体检率未达到95％。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 11)}
              checked={formData.negative[11] === 1}
            />
            没有记录幼儿的身高、体重、头围等基本生长指标，缺乏可比较的数据。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 12)}
              checked={formData.negative[12] === 1}
            />
            未做到3岁以上幼儿每年健康检查1次、4岁以上幼儿每年检查1次视力。
          </CheckboxLabel>
        </CheckboxGroup>
      </CheckboxContainer>

      <Textarea
        name="additionalComments1"
        value={formData.additionalComments1}
        onChange={handleChange}
        placeholder="如有其他证据，请写在下方："
      />

      <SubTitle>积极表现</SubTitle>
      <CheckboxContainer>
        <CheckboxGroup>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 0)}
              checked={formData.positive[0] === 1}
            />
            卫生保健人员与食堂、保育员共同协作进行膳食指导。卫生保健人员为主，其他人员配合。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 1)}
              checked={formData.positive[1] === 1}
            />
            进餐时，教师关注幼儿的个体差异和需要，指导幼儿进餐。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 2)}
              checked={formData.positive[2] === 1}
            />
            进餐氛围轻松愉快。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 3)}
              checked={formData.positive[3] === 1}
            />
            保教人员注意观察幼儿的进餐习惯。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 4)}
              checked={formData.positive[4] === 1}
            />
            卫生保健人员每日深入班级巡视2次。
          </CheckboxLabel>
        </CheckboxGroup>
        <CheckboxGroup>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 5)}
              checked={formData.positive[5] === 1}
            />
            定期开展儿童眼、耳、口腔保健。发现问题儿童及时登记，并督促家长及时带儿童就医。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 6)}
              checked={formData.positive[6] === 1}
            />
            对贫血、营养不良、肥胖等营养性疾病儿童进行登记管理，对中重度贫血和营养不良儿童进行专案管理，督促家长及时带儿童就医。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 7)}
              checked={formData.positive[7] === 1}
            />
            对有冠心病、哮喘、癫痫等疾病的幼儿以及有药物过敏史或食物过敏史的幼儿及时登记。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 8)}
              checked={formData.positive[8] === 1}
            />
            开展儿童心理卫生知识宣传教育。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 9)}
              checked={formData.positive[9] === 1}
            />
            玩具清洗至少每周1次，图书翻晒至少每2周1次。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 10)}
              checked={formData.positive[10] === 1}
            />
            建立幼儿健康（若包括“心理健康”更佳）档案。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 11)}
              checked={formData.positive[11] === 1}
            />
            根据每个幼儿的需要提供针对性的支持和指导。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 12)}
              checked={formData.positive[12] === 1}
            />
            每个幼儿的健康档案资料齐全、信息准确，便于查阅。
          </CheckboxLabel>
        </CheckboxGroup>
      </CheckboxContainer>

      <Textarea
        name="additionalComments2"
        value={formData.additionalComments2}
        onChange={handleChange}
        placeholder="如有其他证据，请写在下方："
      />

      <GradeContainer>
        <div>请根据上述表现对该考查要点进行评级：</div>
        <GradeSelect

          name="grade"
          value={formData.grade}
          onChange={handleChange}
        >
          <option value="">请选择评级</option>
          <option value={1}>优秀</option>
          <option value={2}>良好</option>
          <option value={3}>及格</option>
          <option value={4}>不及格</option>
          <option value={5}>不了解</option>
        </GradeSelect>
      </GradeContainer>

      <Button onClick={handleSubmit}>保存</Button>
    </Container>
  );
};

export default Evaluation44;
