import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom'; // 使用useLocation钩子
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../../api";

const Container = styled.div`
  padding: 20px;
  border: 2px dashed #ccc;  /* 添加虚线边框 */
  border-radius: 10px;      /* 可选：添加圆角 */
`;

const Title = styled.h2`
  text-align: center;
`;

const SubTitle = styled.h3`
  margin-top: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckboxGroup = styled.div`
  flex: 1;
  padding: 10px;
`;

const CheckboxLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-top: 10px;
`;

const GradeContainer = styled.div`
  margin-top: 20px;
`;

const GradeTitle = styled.p`
  margin-bottom: 10px;
`;

const GradeSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Evaluation12 = ({ onSurveyUpdate }) => {
    const location = useLocation();
    const [initialFormData, setInitialFormData] = useState(null);
    const [formData, setFormData] = useState({
        negative: Array(11).fill(0), // 假设有11个消极表现
        positive: Array(11).fill(0), // 假设有11个积极表现
        additionalComments1: '',
        additionalComments2: '',
        grade: '',
        surveyNumber: 12, // 添加问卷序号
        kindergartenId: '', // 添加幼儿园ID
        year: new Date().getFullYear() // 添加年份
    });
    const [currentExpertId, setCurrentExpertId] = useState('');
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const kindergartenId = params.get('kindergartenId');
        const expertId = params.get('expertId');
        setCurrentExpertId(expertId);

        setFormData(prevState => ({
            ...prevState,
            kindergartenId
        }));

        console.log(expertId, formData.year, kindergartenId, formData.surveyNumber); 

        const fetchData = async () => {
            try {
                const response = await api.getExpertGrades2(expertId, formData.year, kindergartenId, formData.surveyNumber);
                if (response.data.success && response.data.data) {
                    setFormData(prevState => ({
                        ...prevState,
                        ...response.data.data
                    }));
                    setInitialFormData(response.data.data);
                } else {
                    setInitialFormData(formData);
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    // 处理404错误，保持表单为空
                    setInitialFormData(formData);
                } else {
                    console.error('Error fetching data:', error);
                }
            } finally {
                setIsDataLoaded(true);
            }
        };

        fetchData();
    }, [location]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (JSON.stringify(initialFormData) !== JSON.stringify(formData)) {
                const message = 'You have unsaved changes, do you really want to leave?';
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [initialFormData, formData]);

    const handleCheckboxChange = (e, type, index) => {
        const { checked } = e.target;
        setFormData(prevState => {
            const updatedList = [...prevState[type]];
            updatedList[index] = checked ? 1 : 0;

            return {
                ...prevState,
                [type]: updatedList
            };
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: name === 'grade' ? Number(value) : value // 将grade转换为数字
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (formData.grade === 0||formData.grade === '') {
            toast.error("您还没选择评级，请选择评级。");
            return;
        }

        const playload = {
            expertId: currentExpertId,
            formData: formData,
        };

        // 提交表单数据
        api.saveExpertGrades2(playload)
            .then(() => {
                // 更新 initialFormData
                setInitialFormData(formData);
                toast.success("保存成功");
                // 调用父组件的更新函数
                onSurveyUpdate(formData.surveyNumber);
            })
            .catch(error => {
                console.error('Error saving data:', error);
                toast.error("保存失败，请重试");
            });
    };

    if (!isDataLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <Container>
            <ToastContainer />
            <Title>考查要点 1.2</Title>
            <SubTitle>消极表现</SubTitle>
            <CheckboxContainer>
                <CheckboxGroup>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 0)}
                            checked={formData.negative[0] === 1}
                        />
                        无党支部但有党员的幼儿园，未与所在社区、附属院校或上级部门建立联合党支部。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 1)}
                            checked={formData.negative[1] === 1}
                        />
                        无党员的幼儿园，未配备一名专兼职党建指导员。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 2)}
                            checked={formData.negative[2] === 1}
                        />
                        幼儿园未设相应部门或岗位来负责党务、工会等工作。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 3)}
                            checked={formData.negative[3] === 1}
                        />
                        幼儿园对习近平总书记重要讲话精神的学习重视不足。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 4)}
                            checked={formData.negative[4] === 1}
                        />
                        幼儿园很少组织教师政治学习。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 5)}
                            checked={formData.negative[5] === 1}
                        />
                        幼儿园教师政治学习内容缺乏针对性。
                    </CheckboxLabel>
                </CheckboxGroup>
                <CheckboxGroup>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 6)}
                            checked={formData.negative[6] === 1}
                        />
                        幼儿园没有落实党风廉政建设责任制。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 7)}
                            checked={formData.negative[7] === 1}
                        />
                        幼儿园意识形态监督管理不到位。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 8)}
                            checked={formData.negative[8] === 1}
                        />
                        有共青团员的幼儿园未积极开展共青团活动。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 9)}
                            checked={formData.negative[9] === 1}
                        />
                        幼儿园没有工会组织。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 10)}
                            checked={formData.negative[10] === 1}
                        />
                        有工会的幼儿园未积极开展工会活动。
                    </CheckboxLabel>
                </CheckboxGroup>
            </CheckboxContainer>

            <Textarea
                name="additionalComments1"
                value={formData.additionalComments1}
                onChange={handleChange}
                placeholder="如有其他证据，请写在下方："
            />

            <SubTitle>积极表现</SubTitle>
            <CheckboxContainer>
                <CheckboxGroup>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 0)}
                            checked={formData.positive[0] === 1}
                        />
                        幼儿园设立了党支部。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 1)}
                            checked={formData.positive[1] === 1}
                        />
                        无党支部的幼儿园，积极与所在社区、附属院校、上级部门建立联合党支部。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 2)}
                            checked={formData.positive[2] === 1}
                        />
                        无党员的幼儿园，配备了一名专兼职党建指导员。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 3)}
                            checked={formData.positive[3] === 1}
                        />
                        幼儿园坚持党的领导，组织架构合理，分工明确，职责清晰。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 4)}
                            checked={formData.positive[4] === 1}
                        />
                        幼儿园定期开展习近平总书记重要讲话精神的学习。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 5)}
                            checked={formData.positive[5] === 1}
                        />
                        幼儿园建立教师常态化的政治理论学习制度，每月至少组织1次教职工政治学习。
                    </CheckboxLabel>
                </CheckboxGroup>
                <CheckboxGroup>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 6)}
                            checked={formData.positive[6] === 1}
                        />
                        幼儿园教师政治学习内容丰富，条理清晰，针对性强。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 7)}
                            checked={formData.positive[7] === 1}
                        />
                        幼儿园建立了党风廉政责任制，并得到较好的贯彻落实。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 8)}
                            checked={formData.positive[8] === 1}
                        />
                        幼儿园建立了意识形态工作责任制。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 9)}
                            checked={formData.positive[9] === 1}
                        />
                        幼儿园注重加强对幼儿园网站、微信公众号、广播、宣传栏、宣传资料等在意识形态方面的监督管理。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 10)}
                            checked={formData.positive[10] === 1}
                        />
                        幼儿园共青团定期开展共青团活动。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 11)}
                            checked={formData.positive[11] === 1}
                        />
                        幼儿园工会定期开展工会活动。
                    </CheckboxLabel>
                </CheckboxGroup>
            </CheckboxContainer>

            <Textarea
                name="additionalComments2"
                value={formData.additionalComments2}
                onChange={handleChange}
                placeholder="如有其他证据，请写在下方："
            />

            <GradeContainer>
                <GradeTitle>
                请根据上述表现对该考查要点进行评级：
                </GradeTitle>
                <GradeSelect
                    name="grade"
                    value={formData.grade}
                    onChange={handleChange}
                >
                    <option value="">请选择评级</option>
                    <option value={1}>优秀</option>
                    <option value={2}>良好</option>
                    <option value={3}>及格</option>
                    <option value={4}>不及格</option>
                    <option value={5}>不了解</option>
                </GradeSelect>
            </GradeContainer>
            <Button onClick={handleSubmit}>保存</Button>
        </Container>
    );
};

export default Evaluation12;
