import React from 'react';
import { useForm } from "react-hook-form";
import styled from "styled-components";
import api from "../api";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";

const Styles = styled.div`
 background: lavender;
 padding: 20px;

 h1 {
   border-bottom: 1px solid white;
   color: #3d3d3d;
   font-family: sans-serif;
   font-size: 20px;
   font-weight: 600;
   line-height: 24px;
   padding: 10px;
   text-align: center;
 }

 form {
   background: white;
   border: 1px solid #dedede;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   margin: 0 auto;
   max-width: 500px;
   padding: 30px 50px;
 }

 input {
   border: 1px solid #d9d9d9;
   border-radius: 4px;
   box-sizing: border-box;
   padding: 10px;
   width: 100%;
 }

 label {
   color: #3d3d3d;
   display: block;
   font-family: sans-serif;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 5px;
 }

 .error {
   color: red;
   font-family: sans-serif;
   font-size: 12px;
   height: 30px;
 }

 .submitButton {
   background-color: #6976d9;
   color: white;
   font-family: sans-serif;
   font-size: 14px;
   margin: 20px 0;
`;

// 定义表单验证规则
const formSchema = Yup.object().shape({
    email: Yup.string()
        .required('邮箱为必填项')
        .email('邮箱格式不正确'),
    realName: Yup.string()
        .required('真实姓名为必填项'),
    password: Yup.string()
        .min(6, '密码长度至少为6个字符')
        .max(32, '密码长度不能超过32个字符')
        .required('密码为必填项'),
    confirmPwd: Yup.string()
        .required('请再次确认密码')
        .oneOf([Yup.ref('password')], '两次输入的密码不一致'),
    evaluatorType: Yup.string()
        .required('请选择评估人员类型'),
});

const ExpertRegistration = () => {
    const formOptions = { resolver: yupResolver(formSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const navigate = useNavigate(); 

    const onSubmit = async (data) => {
        const { email, realName, password, evaluatorType } = data;
        const payload = {
            email,
            realName,
            password,      
            evaluatorType,      
        };
        try {
            await api.insertExperts(payload); // 假设有对应的 API 方法
            toast.success('注册成功！请等待审核。');
            navigate('/login'); // 注册成功后可能需要跳转至登录页
        } catch (error) {
            toast.error(`注册失败：${error.message}`);
        }
    };
 
    return (
        <Styles>
            <ToastContainer />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <input name="email" type="email" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} placeholder="请输入邮箱" />
                    <div className="invalid-feedback">{errors.email?.message}</div>
                </div>
                <div className="form-group">
                    <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} placeholder="请输入密码" />
                    <div className="invalid-feedback">{errors.password?.message}</div>
                </div>
                <div className="form-group">
                    <input name="confirmPwd" type="password" {...register('confirmPwd')} className={`form-control ${errors.confirmPwd ? 'is-invalid' : ''}`} placeholder="请再次输入密码" />
                    <div className="invalid-feedback">{errors.confirmPwd?.message}</div>
                </div>
                <div className="form-group">
                    <input name="realName" type="text" {...register('realName')} className={`form-control ${errors.realName ? 'is-invalid' : ''}`} placeholder="真实姓名" />
                    <div className="invalid-feedback">{errors.realName?.message}</div>
                </div>                
                <div className="form-group">                    
                    <select name="evaluatorType" {...register('evaluatorType')} className={`form-control ${errors.evaluatorType ? 'is-invalid' : ''}`}>
                        <option value="">评估人员类型</option>
                        <option value="组长">组长</option>
                        <option value="专家组研究生">专家组研究生</option>
                        <option value="教育局（行政）人员">教育局（行政）人员</option>
                        <option value="教育局（教研）人员">教育局（教研）人员</option>
                        <option value="本期被评估幼儿园园长">本期被评估幼儿园园长</option>
                        <option value="往期被评估幼儿园园长">往期被评估幼儿园园长</option>
                    </select>
                    <div className="invalid-feedback">{errors.evaluatorType?.message}</div>
                </div>
                <div className="mt-3">
                    <input type="submit" className="submitButton" value="注册" />
                </div>
            </form>
        </Styles>
    );
};

export default ExpertRegistration;
