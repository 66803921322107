import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Authenticated from "../utils/Authenticated";

const Styles = styled.div`
 background: lavender;
 padding: 20px;

 h1 {
   border-bottom: 1px solid white;
   color: #3d3d3d;
   font-family: sans-serif;
   font-size: 20px;
   font-weight: 600;
   line-height: 24px;
   padding: 10px;
   text-align: center;
 }

 form {
   background: white;
   border: 1px solid #dedede;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   margin: 0 auto;
   max-width: 500px;
   padding: 30px 50px;
 }

 input {
   border: 1px solid #d9d9d9;
   border-radius: 4px;
   box-sizing: border-box;
   padding: 10px;
   width: 100%;
 }

 label {
   color: #3d3d3d;
   display: block;
   font-family: sans-serif;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 5px;
 }

 .error {
   color: red;
   font-family: sans-serif;
   font-size: 12px;
   height: 30px;
 }

 .submitButton {
   background-color: #6976d9;
   color: white;
   font-family: sans-serif;
   font-size: 14px;
   margin: 20px 0px;
`;



const Wrapper = styled.div`
  padding: 240px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('https://dch.georgia.gov/sites/dch.georgia.gov/files/styles/3_2_2106px_x_1404px/public/2020-02/pck%20kids.jpg?h=9d96f97f&itok=npnQiCp_');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.8);
  width: 80vw;
  height: 80vh;
  margin: 0 auto; // 居中显示
`;


const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  margin: 0 auto;
`;

const Cell = styled.div`
  flex: 1;
  padding: 5px;
  background-color: white;
  border: 1px solid #ccc;
  text-align: center;
`;

const CellHead = styled.div`
  flex: 1;
  padding: 5px;  
  background: linear-gradient(#ffd700, #808080);
  border: 1px solid #ccc;
  text-align: center;
  font-weight: bold;
  font-size: 34px;
`;




const LoginView = () => {
  const { register, handleSubmit, watch } = useForm();
  const navigate = useNavigate();
  const isExpertChecked = watch("isExpert", false); // 默认不选中

  const onSubmit = async (data) => {
    const { email, password } = data;  // 修改为使用电子邮件和密码
    const userType = isExpertChecked ? 'expert' : 'teacher';
    const payload = {
      username: email,
      password: password,
      userType: userType
    };

    try {
      const res = await api.teacherLogin(payload);  // 修改 API 调用以适应教师登录
      const role = res.data.role;
      // const account = await Authenticated()
      // console.log(account)
      toast.success('登录成功！', { autoClose: 500 });

      // 如果登录成功的用户是 admin@qq.com
      if (email === "admin@qq.com") {
        // 直接跳转到外部链接
        navigate("/dashboard", {
          state: {
            teacherId: res.data.teacherId,
            realName: res.data.realName,
            kindergartenId: res.data.kindergartenId
          }
        });
      } else if (role === 'teacher') {
        // 对于其他用户，使用内部路由跳转
        navigate("/evaluationForm", {
          state: {
            teacherId: res.data.teacherId,
            realName: res.data.realName,
            kindergartenId: res.data.kindergartenId
          }
        });
      } else if (role === 'expert') {
        // navigate("/ExpertReviewPage", {
          navigate("/ExpertLoginDashboard", {
          state: {
            expertId: res.data.teacherId,
            realName: res.data.realName,
          }
        });
      }
    } catch (err) {
      console.error(err);
      if (err.response.data.message === "Role is guest") {
        toast.error('用户未授权，请联系管理员进行审核！', { autoClose: 1000 });
      }
      else
        toast.error('用户名或密码错误！', { autoClose: 500 });
    }
  };

  const navigateToRegister = () => {
    navigate('/register'); // 导航到注册页面
  };

  return (
    <Wrapper>
      <ToastContainer />
      <Row>
        <CellHead>
          长春市幼儿园保育教育质量评估系统
        </CellHead>
      </Row>
      <Row>
        <Cell>
          <Styles>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input {...register("email", { required: true })} placeholder="邮箱" />
              <input type="password" {...register("password", { required: true })} placeholder="密码" />


              <input type="submit" className="submitButton" value="登录" />
              {/* 添加注册按钮，风格与登录按钮一致 */}
              {/* <button type="button" className="submitButton" onClick={navigateToRegister}>注册</button> */}
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <a href="/register" style={{ color: '#6976d9', textDecoration: 'none' }}>注册新用户</a>
                <a href="/ExpertRegistration" style={{ color: '#6976d9', textDecoration: 'none' }}>专家注册</a>
                <a href="/forgot-password" style={{ color: '#6976d9', textDecoration: 'none' }}>忘记密码？</a>
              </div>

            </form>
          </Styles>
          <label style={{ display: 'flex', alignItems: 'right', margin: '10px 10px 10px 450px' }}>
            <input type="checkbox" {...register("isExpert")} />
            <span style={{ marginLeft: '1px' }}>专家登录</span>
          </label>
        </Cell>
      </Row>
    </Wrapper>
  );
}

export default LoginView;