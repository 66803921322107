import api from "../api";

const getAuthenticated = async () => {
    try {
        const account = await api.getAuthenticated()
        return {
            login:true,
            username: account.data.user.username,
            role: account.data.user.role,
            email: account.data.user.email
        }
    } catch(err) {
        console.log(err)
        return {login:false}
    }

}

export default getAuthenticated
