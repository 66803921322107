import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../../api";

const Container = styled.div`
  padding: 20px;
  border: 2px dashed #ccc;
  margin: 20px;
`;

const Title = styled.h2`
  text-align: center;
`;

const SubTitle = styled.h3`
  margin-top: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckboxGroup = styled.div`
  flex: 1;
  padding: 10px;
`;

const CheckboxLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-top: 10px;
`;

const GradeContainer = styled.div`
  margin-top: 20px;
`;

const GradeSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Evaluation42 = ({ onSurveyUpdate }) => {
  const location = useLocation();
  const [initialFormData, setInitialFormData] = useState(null);
  const [formData, setFormData] = useState({
    negative: Array(3).fill(0),
    positive: Array(10).fill(0),
    additionalComments1: '',
    additionalComments2: '',
    grade: '',
    surveyNumber: 42, // 添加问卷序号
    kindergartenId: '', // 添加幼儿园ID
    year: new Date().getFullYear() // 添加年份
  });
  const [currentExpertId, setCurrentExpertId] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const kindergartenId = params.get('kindergartenId');
    const expertId = params.get('expertId');
    setCurrentExpertId(expertId);

    setFormData(prevState => ({
      ...prevState,
      kindergartenId
    }));

    const fetchData = async () => {
      try {
        const response = await api.getExpertGrades2(expertId, formData.year, kindergartenId, formData.surveyNumber);
        if (response.data.success && response.data.data) {
          setFormData(prevState => ({
            ...prevState,
            ...response.data.data
          }));
          setInitialFormData(response.data.data);
        } else {
          setInitialFormData(formData);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setInitialFormData(formData);
        } else {
          console.error('Error fetching data:', error);
        }
      } finally {
        setIsDataLoaded(true);
      }
    };

    fetchData();
  }, [location]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (JSON.stringify(initialFormData) !== JSON.stringify(formData)) {
        const message = 'You have unsaved changes, do you really want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [initialFormData, formData]);

  const handleCheckboxChange = (e, type, index) => {
    const { checked } = e.target;
    setFormData(prevState => {
      const updatedList = [...prevState[type]];
      updatedList[index] = checked ? 1 : 0;

      return {
        ...prevState,
        [type]: updatedList
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'grade' ? Number(value) : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    
    if (formData.grade === 0||formData.grade === '') {
        toast.error("您还没选择评级，请选择评级。");
        return;
    }

    const playload = {
        expertId: currentExpertId,
        formData: formData,
    };

    // 提交表单数据
    api.saveExpertGrades2(playload)
      .then(() => {
        // 更新 initialFormData
        setInitialFormData(formData);
        toast.success("保存成功");
        // 调用父组件的更新函数
        onSurveyUpdate(formData.surveyNumber);
      })
      .catch(error => {
        console.error('Error saving data:', error);
        toast.error("保存失败，请重试");
      });
  };

  if (!isDataLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <ToastContainer />
      <Title>考查要点 4.2</Title>
      <SubTitle>消极表现</SubTitle>
      <CheckboxContainer>
        <CheckboxGroup>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 0)}
              checked={formData.negative[0] === 1}
            />
            无带量食谱和计划。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 1)}
              checked={formData.negative[1] === 1}
            />
            幼儿园未开展有关健康饮食的教育活动。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 2)}
              checked={formData.negative[2] === 1}
            />
            教师未在幼儿用餐时提醒幼儿用餐习惯，如洗手、坐好、慢慢咀嚼食物等。
          </CheckboxLabel>
        </CheckboxGroup>
      </CheckboxContainer>

      <Textarea
        name="additionalComments1"
        value={formData.additionalComments1}
        onChange={handleChange}
        placeholder="如有其他证据，请写在下方："
      />

      <SubTitle>积极表现</SubTitle>
      <CheckboxContainer>
        <CheckboxGroup>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 0)}
              checked={formData.positive[0] === 1}
            />
            膳食营养达标。幼儿园至少每季度进行1次膳食调查和营养评估。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 1)}
              checked={formData.positive[1] === 1}
            />
            为贫血、营养不良、食物过敏等儿童提供特殊膳食。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 2)}
              checked={formData.positive[2] === 1}
            />
            食物品种要多样化且合理搭配，如定期在食材中加入牛羊肉，同时保证膳食纤维的摄入。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 3)}
              checked={formData.positive[3] === 1}
            />
            每周更换食谱。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 4)}
              checked={formData.positive[4] === 1}
            />
            幼儿园周向家长公示幼儿食谱。每月进行家长幼儿食谱情况调查，发放问卷以了解家长对食谱的反馈。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 5)}
              checked={formData.positive[5] === 1}
            />
            参照相关规定进行食品留样。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 6)}
              checked={formData.positive[6] === 1}
            />
            食物定量进班，结合每月营养分析的结果，及时采取有效调整措施。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 7)}
              checked={formData.positive[7] === 1}
            />
            支持幼儿自主饮水，根据幼儿自主饮水的情况，适当组织幼儿集体饮水。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 8)}
              checked={formData.positive[8] === 1}
            />
            教师帮助或指导幼儿自主取餐、进餐，减少等待时间，中大班幼儿可使用筷子用餐。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 9)}
              checked={formData.positive[9] === 1}
            />
            将健康教育融入幼儿一日生活，制定安全和用餐制度。
          </CheckboxLabel>
        </CheckboxGroup>
      </CheckboxContainer>

      <Textarea
        name="additionalComments2"
        value={formData.additionalComments2}
        onChange={handleChange}
        placeholder="如有其他证据，请写在下方："
      />

      <GradeContainer>
        <div>请根据上述表现对该考查要点进行评级：</div>
        <GradeSelect

          name="grade"
          value={formData.grade}
          onChange={handleChange}
        >
          <option value="">请选择评级</option>
          <option value={1}>优秀</option>
          <option value={2}>良好</option>
          <option value={3}>及格</option>
          <option value={4}>不及格</option>
          <option value={5}>不了解</option>
        </GradeSelect>
      </GradeContainer>

      <Button onClick={handleSubmit}>保存</Button>
    </Container>
  );
};

export default Evaluation42;
