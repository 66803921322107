import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../../api"; // 假设您有一个api模块处理数据请求

const Container = styled.div`
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  text-align: center;
`;

const SubTitle = styled.h3`
  margin-top: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckboxGroup = styled.div`
  flex: 1;
  padding: 10px;
`;

const CheckboxLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-top: 10px;
`;

const GradeSelectContainer = styled.div`
  margin-top: 20px;
`;

const GradeSelectLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const GradeSelect = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const ErrorMsg = styled.div`
  color: red;
  margin-top: 10px;
`;

const SuccessMsg = styled.div`
  color: green;
  margin-top: 10px;
`;

const Evaluation101 = ({ onSurveyUpdate }) => {
  const location = useLocation();
  const [initialFormData, setInitialFormData] = useState(null);
  const [formData, setFormData] = useState({
    negative: Array(7).fill(0), 
    positive: Array(6).fill(0), 
    additionalComments1: '',
    additionalComments2: '',
    grade: '',
    surveyNumber: 101, 
    kindergartenId: '', 
    year: new Date().getFullYear() 
  });
  const [currentExpertId, setCurrentExpertId] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const kindergartenId = params.get('kindergartenId');
    const expertId = params.get('expertId');
    setCurrentExpertId(expertId);

    setFormData(prevState => ({
      ...prevState,
      kindergartenId
    }));

    const fetchData = async () => {
      try {
        const response = await api.getExpertGrades2(expertId, formData.year, kindergartenId, formData.surveyNumber);
        if (response.data.success && response.data.data) {
          setFormData(prevState => ({
            ...prevState,
            ...response.data.data
          }));
          setInitialFormData(response.data.data);
        } else {
          setInitialFormData(formData);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setInitialFormData(formData);
        } else {
          console.error('Error fetching data:', error);
        }
      } finally {
        setIsDataLoaded(true);
      }
    };

    fetchData();
  }, [location]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (JSON.stringify(initialFormData) !== JSON.stringify(formData)) {
        const message = 'You have unsaved changes, do you really want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [initialFormData, formData]);

  const handleCheckboxChange = (e, type, index) => {
    const { checked } = e.target;
    setFormData(prevState => {
      const updatedList = [...prevState[type]];
      updatedList[index] = checked ? 1 : 0;
      return { ...prevState, [type]: updatedList };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'grade' ? Number(value) : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    
    if (formData.grade === 0||formData.grade === '') {
        toast.error("您还没选择评级，请选择评级。");
        return;
    }

    const playload = {
        expertId: currentExpertId,
        formData: formData,
    };

    // 提交表单数据
    api.saveExpertGrades2(playload)
        .then(() => {
            // 更新 initialFormData
            setInitialFormData(formData);
            toast.success("保存成功");
            // 调用父组件的更新函数
            onSurveyUpdate(formData.surveyNumber);
        })
        .catch(error => {
            console.error('Error saving data:', error);
            toast.error("保存失败，请重试");
        });
};

  if (!isDataLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <ToastContainer />
      <Title>考查要点 10.1</Title>
      <SubTitle>消极表现</SubTitle>
      <CheckboxContainer>
        <CheckboxGroup>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 0)}
              checked={formData.negative[0] === 1}
            />
            幼儿园的小班班额多于25人，中班班额多于30人，大班班额多于35人，混合班班额多于30人。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 1)}
              checked={formData.negative[1] === 1}
            />
            全园共用活动场地人均面积小于2㎡。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 2)}
              checked={formData.negative[2] === 1}
            />
            班级活动室面积小于70㎡。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 3)}
              checked={formData.negative[3] === 1}
            />
            若活动室与寝室合用，其房间使用面积小于120㎡。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 4)}
              checked={formData.negative[4] === 1}
            />
            卫生间面积小于12㎡。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 5)}
              checked={formData.negative[5] === 1}
            />
            幼儿园既无沙池也无水池。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 6)}
              checked={formData.negative[6] === 1}
            />
            未达到6个班级规模的幼儿园设专门功能室。
          </CheckboxLabel>
        </CheckboxGroup>
      </CheckboxContainer>

      <Textarea
        name="additionalComments1"
        value={formData.additionalComments1}
        onChange={handleChange}
        placeholder="如有其他证据，请写在下方："
      />

      <SubTitle>积极表现</SubTitle>
      <CheckboxContainer>
        <CheckboxGroup>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 0)}
              checked={formData.positive[0] === 1}
            />
            幼儿园各类用房的使用面积符合规定。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 1)}
              checked={formData.positive[1] === 1}
            />
            幼儿的活动室、午睡室、卫生间、衣帽储藏设计成独立使用单元。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 2)}
              checked={formData.positive[2] === 1}
            />
            卫生间临近活动室和寝室。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 3)}
              checked={formData.positive[3] === 1}
            />
            活动室内设置3-4个常设的活动区。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 4)}
              checked={formData.positive[4] === 1}
            />
            幼儿园开辟沙地、水池、种植园等。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 5)}
              checked={formData.positive[5] === 1}
            />
            综合活动室能够同时容纳两个班级及以上幼儿开展集体活动或亲子活动。
          </CheckboxLabel>
        </CheckboxGroup>
      </CheckboxContainer>

      <Textarea
        name="additionalComments2"
        value={formData.additionalComments2}
        onChange={handleChange}
        placeholder="如有其他证据，请写在下方："
      />

      <GradeSelectContainer>
        <GradeSelectLabel>请根据上述表现对该考查要点进行评级：</GradeSelectLabel>
        <GradeSelect
          name="grade"
          value={formData.grade}
          onChange={handleChange}
        >
          <option value="">请选择评级</option>
          <option value={1}>优秀</option>
          <option value={2}>良好</option>
          <option value={3}>及格</option>
          <option value={4}>不及格</option>
          <option value={5}>不了解</option>
        </GradeSelect>
      </GradeSelectContainer>


      {error && <ErrorMsg>{error}</ErrorMsg>}
      {success && <SuccessMsg>{success}</SuccessMsg>}

      <Button onClick={handleSubmit}>保存</Button>
    </Container>
  );
};

export default Evaluation101;
