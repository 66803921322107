import React, { useState, useEffect } from 'react';
import api from '../api'; // 假设你有一个API文件来处理HTTP请求
import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const InlineContainer = styled.div`
  display: flex;
  align-items: center; // 确保子元素垂直居中
  gap: 10px; // 在子元素之间添加一些间隔
`;

const PageContainer = styled.div`
  padding: 20px;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const SectionContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

const FlexContainer = styled.div`
  flex: 1;
`;

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const Button = styled.button`
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  background-color: #4CAF50; /* Green */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const Ul = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const Li = styled.li`
  margin: 5px 0;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate; /* 让单元格之间可以有间距 */
  border-spacing: 0 10px; /* 设置单元格之间的间距 */
`;

const Th = styled.th`
  padding: 10px;
  text-align: left;
  background-color: #4CAF50;
  color: white;
  border-bottom: 1px solid #ddd;
`;

const Td = styled.td`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  background-color: #f9f9f9; /* 设置一个轻微的背景色 */
  /* 使用 :first-child 和 :last-child 选择器添加圆角 */
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const EditInput = styled.input`
  padding: 5px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 95%; /* 编辑框内部留有边距 */
`;

const ButtonTable = styled.button`
  padding: 5px 10px;
  margin-right: 5px; /* 按钮之间的间距 */
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Select = styled.select`
  padding: 5px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: calc(100% - 10px); /* 调整宽度以适应边距 */
`;

const UserInfoContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: right;
  font-size: 18px;
  color: #3d3d3d;
  z-index: 1000;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center; // 确保子元素垂直居中
  gap: 10px; // 在子元素之间添加一些间隔
`;

const Circle = styled.div`
  width: 30px;
  height: 30px;
  margin: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ddd; // 默认背景色
  color: #333; // 默认字体颜色
  cursor: pointer;

  &.selected {
    background-color: #4CAF50; // 选中时的背景色
    color: white; // 选中时的字体颜色
  }
`;

// 添加新的样式组件
const FilterContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

const FilterSelect = styled.select`
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  min-width: 200px;
`;

const ExpertsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [experts, setExperts] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingPasswordIndex, setEditingPasswordIndex] = useState(null);
  const [password, setPassword] = useState("");
  const [threshold, setThreshold] = useState(0);
  const [count, setCount] = useState(0);
  let { expertId, realName } = location.state || {};

  // 添加筛选状态
  const [roleFilter, setRoleFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');
  const [yearFilter, setYearFilter] = useState('all');

  // 修改筛选专家列表的函数
  const filteredExperts = experts.filter(expert => {
    const roleMatch = roleFilter === 'all' || expert.role === roleFilter;
    const typeMatch = typeFilter === 'all' || expert.evaluatorType === typeFilter;
    
    // 添加年份筛选
    const yearMatch = yearFilter === 'all' || (
      expert.grades?.some(grade => 
        grade.year === yearFilter
        
      )
    );
    

    return roleMatch && typeMatch && yearMatch;
  });

  useEffect(() => {
    const fetchExperts = async () => {
      try {
        const response = await api.getExperts(); // 假设这个函数返回所有专家的列表
        setExperts(response.data.data);
      } catch (error) {
        console.error("Failed to fetch experts:", error);
      }
    };

    fetchExperts();
  }, []);

  useEffect(() => {
    const filteredCount = experts.reduce((acc, expert) => {
      const totalRequiredGrades = expert.selectedNumbers.length;
      const quantitativeGrades = expert.grades?.find(g => g.year === yearFilter)?.quantitativeGrades;

      
      const completedGradesCount = quantitativeGrades
        ? Object.values(expert.grades[0].quantitativeGrades).filter((grade) => {
          return grade && Object.keys(grade).length === totalRequiredGrades;
        }).length
        : 0;
      return acc + (completedGradesCount >= threshold ? 1 : 0);
    }, 0);
    setCount(filteredCount);
  }, [threshold, experts]);



  const handleEdit = (index) => {
    const newExperts = [...experts];
    newExperts[index].isEditing = true;
    newExperts[index].editRealName = newExperts[index].realName;
    newExperts[index].editEmail = newExperts[index].email;
    newExperts[index].editRole = newExperts[index].role;
    newExperts[index].editEvaluatorType = newExperts[index].evaluatorType;
    setExperts(newExperts);
    setEditingIndex(index);
  };


  const handleCancelEdit = (index) => {
    const newExperts = [...experts];
    newExperts[index].isEditing = false;
    setExperts(newExperts);
    setEditingIndex(null);
  };

  const handleChange = (index, field, value) => {
    const newExperts = [...experts];
    newExperts[index][field] = value;
    setExperts(newExperts);
  };

  const handleSave = async (index) => {
    const expert = experts[index];
    try {
      await api.updateExpertById({
        id: expert._id,
        realName: expert.editRealName,
        email: expert.editEmail,
        role: expert.editRole,
        evaluatorType: expert.editEvaluatorType
      });
      const newExperts = [...experts];
      newExperts[index].realName = expert.editRealName;
      newExperts[index].email = expert.editEmail;
      newExperts[index].role = expert.editRole;
      newExperts[index].evaluatorType = expert.editEvaluatorType;
      newExperts[index].isEditing = false;
      setExperts(newExperts);
      setEditingIndex(null);
      toast.success("保存成功");
    } catch (error) {
      console.error("Failed to save expert changes:", error);
      toast.error("保存失败");
    }
  };

  const handleSavePassword = async (expertId, index) => {
    if (!password) {
      alert("请输入密码");
      return;
    }
    try {
      await api.updateExpertPassword({ id: expertId, newPassword: password });
      alert("密码更新成功");
      setPassword("");
      setEditingPasswordIndex(null);
    } catch (error) {
      console.error("更新密码失败:", error);
      alert("密码更新失败");
    }
  };

  const handleLogout = () => {
    navigate('/login'); // 跳转到登录页面
  };

  const handleToggleSelectedNumber = (expertIndex, number) => {
    const newExperts = [...experts];
    const selectedNumbers = newExperts[expertIndex].selectedNumbers || [];

    if (selectedNumbers.includes(number)) {
      // 如果数字已被选中，移除它
      newExperts[expertIndex].selectedNumbers = selectedNumbers.filter(n => n !== number);
    } else {
      // 否则，添加数字到选中列表
      newExperts[expertIndex].selectedNumbers = [...selectedNumbers, number];
    }

    setExperts(newExperts);
    // 这里可以添加一个API调用，来更新服务器上的专家数据
  };

  const handleSaveSelectedNumbers = async (expertId, index) => {
    const expert = experts[index];
    try {
      // 调用API更新专家选中的数字
      await api.updateExpertSettings({
        id: expertId,
        selectedNumbers: expert.selectedNumbers,
      });
      toast.success("专家权限设置成功");
    } catch (error) {
      console.error("专家权限设置失败:", error);
      toast.error("专家权限设置失败");
    }
  };

  const handleThresholdChange = (e) => {
    setThreshold(Number(e.target.value));
  };


  return (
    <PageContainer>
      <ToastContainer />
      <UserInfoContainer>
        {realName}
        <button onClick={handleLogout} style={{ marginLeft: '20px', padding: '5px 10px', fontSize: '16px', cursor: 'pointer', borderRadius: '5px', border: 'none', backgroundColor: '#6976d9', color: 'white' }}>
          登出
        </button>
      </UserInfoContainer>
      <h1>专家管理</h1>

      {/* 添加筛选器 */}
      <FilterContainer>
        <div>
          <label>角色筛选：</label>
          <FilterSelect 
            value={roleFilter} 
            onChange={(e) => setRoleFilter(e.target.value)}
          >
            <option value="all">全部角色</option>
            <option value="guest">游客</option>
            <option value="expert">专家</option>
          </FilterSelect>
        </div>

        <div>
          <label>人员类型筛选：</label>
          <FilterSelect 
            value={typeFilter} 
            onChange={(e) => setTypeFilter(e.target.value)}
          >
            <option value="all">全部类型</option>
            <option value="组长">组长</option>
            <option value="专家组研究生">专家组研究生</option>
            <option value="教育局（行政）人员">教育局（行政）人员</option>
            <option value="教育局（教研）人员">教育局（教研）人员</option>
            <option value="本期被评估幼儿园园长">本期被评估幼儿园园长</option>
            <option value="往期被评估幼儿园园长">往期被评估幼儿园园长</option>
          </FilterSelect>
        </div>

        <div>
          <label>评审年筛选：</label>
          <FilterSelect 
            value={yearFilter} 
            onChange={(e) => setYearFilter(e.target.value)}
          >
            <option value="all">全部年份</option>
            {[...Array(20).keys()].map((i) => {
              const year = 2024 + i;
              return <option key={year} value={year}>{year}</option>;
            })}
          </FilterSelect>
        </div>
      </FilterContainer>

      <InlineContainer>
        <input
          type="number"
          value={threshold}
          onChange={handleThresholdChange}
          placeholder="输入数字"
          style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
        />
        <span>符合条件的专家数量: {count}</span>
      </InlineContainer>

      <Table>
        <thead>
          <tr>
            <Th>行号</Th>
            <Th>姓名</Th>
            <Th>电子邮件</Th>
            <Th>角色</Th>
            <Th>人员类型</Th>
            <Th>操作</Th>
            <Th>题目权限</Th>
            <Th>保存</Th>
            <Th>评审数</Th>
          </tr>
        </thead>
        <tbody>
          {/* 使用筛选后的列表 filteredExperts 替代 experts */}
          {filteredExperts.map((expert, index) => (
            <tr key={expert._id}>
              <Td>{index + 1}</Td>
              <Td>
                {expert.isEditing ? (
                  <EditInput
                    type="text"
                    value={expert.editRealName}
                    onChange={(e) => handleChange(index, 'editRealName', e.target.value)}
                  />
                ) : (
                  expert.realName
                )}
              </Td>
              <Td>
                {expert.email}
              </Td>
              <Td>
                {expert.isEditing ? (
                  <Select
                    value={expert.editRole}
                    onChange={(e) => handleChange(index, 'editRole', e.target.value)}
                  >
                    <option value="guest">游客</option>
                    <option value="expert">专家</option>
                  </Select>
                ) : (
                  expert.role === 'guest' ? '游客' : '专家'
                )}
              </Td>
              <Td>
                {expert.isEditing ? (
                  <Select
                    value={expert.editEvaluatorType || expert.evaluatorType}
                    onChange={(e) => handleChange(index, 'editEvaluatorType', e.target.value)}
                  >
                    <option value="">请选择人员类型</option>
                    <option value="组长">组长</option>
                    <option value="专家组研究生">专家组研究生</option>
                    <option value="教育局（行政）人员">教育局（行政）人员</option>
                    <option value="教育局（教研）人员">教育局（教研）人员</option>
                    <option value="本期被评估幼儿园园长">本期被评估幼儿园园长</option>
                    <option value="往期被评估幼儿园园长">往期被评估幼儿园园长</option>
                  </Select>
                ) : (
                  expert.evaluatorType || '未设置'
                )}
              </Td>
              <Td>
                <ButtonGroup>
                  {expert.isEditing ? (
                    <>
                      <ButtonTable onClick={() => handleSave(index)}>保存</ButtonTable>
                      <ButtonTable onClick={() => handleCancelEdit(index)}>取消</ButtonTable>
                    </>
                  ) : (
                    <>
                      <ButtonTable onClick={() => handleEdit(index)}>编辑</ButtonTable>
                      {index === editingPasswordIndex ? (
                        <InlineContainer>
                          <EditInput
                            type="password"
                            placeholder="新密码"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <ButtonTable onClick={() => handleSavePassword(expert._id, index)}>
                            保存密码
                          </ButtonTable>
                        </InlineContainer>
                      ) : (
                        <ButtonTable onClick={() => setEditingPasswordIndex(index)}>
                          修改密码
                        </ButtonTable>
                      )}
                    </>
                  )}
                </ButtonGroup>
              </Td>
              <Td>
                {[...Array(15).keys()].map((number) => (
                  <Circle
                    key={number}
                    className={expert.selectedNumbers?.includes(number + 1) ? 'selected' : ''}
                    onClick={() => handleToggleSelectedNumber(index, number + 1)}
                  >
                    {number + 1}
                  </Circle>
                ))}
              </Td>
              <Td>
                <Button onClick={() => handleSaveSelectedNumbers(expert._id, index)}>保存</Button>
              </Td>
              <Td>
                {expert.grades && expert.grades.length > 0 
                  ? (() => {
                      const yearGrades = expert.grades.find(g => g.year === yearFilter);
                      return yearGrades?.quantitativeGrades 
                        ? Object.values(yearGrades.quantitativeGrades).filter((grade) => {
                            return grade && Object.keys(grade).length >= expert.selectedNumbers.length;
                          }).length
                        : 0;
                    })()
                  : 0}
              </Td>




            </tr>
          ))}
        </tbody>
      </Table>
    </PageContainer>
  );
};

export default ExpertsPage;