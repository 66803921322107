import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from "react-toastify";
import styled from 'styled-components';
import api from '../api';

const PageContainer = styled.div`
  padding: 20px;
`;

const SettingsPage = () => {
  const [roundOneDistribution, setRoundOneDistribution] = useState({
    A: 0, B: 0, C: 0
  });
  const [roundTwoDistribution, setRoundTwoDistribution] = useState({
    'A+': 0, A: 0, 'B+': 0, B: 0, 'B-': 0, 'C': 0, 'C-': 0
  });
  const [showFloatingWindow, setShowFloatingWindow] = useState(false);

  const roundOneOrder = ['A', 'B', 'C'];
  const roundTwoOrder = ['A+', 'A', 'B+', 'B', 'B-', 'C', 'C-'];

  const handleInputChange = (event, round, grade) => {
    const value = parseInt(event.target.value, 10);
    if (round === 1) {
      setRoundOneDistribution(prev => ({ ...prev, [grade]: value }));
    } else {
      setRoundTwoDistribution(prev => ({ ...prev, [grade]: value }));
    }
  };

  const toggleFloatingWindow = () => {
    setShowFloatingWindow(prev => !prev);
  };

  const saveSettings = async () => {
    try {
      await api.saveDistributionData({
        firstRoundDistribution: roundOneDistribution,
        secondRoundDistribution: roundTwoDistribution,
        showFloatingWindow: showFloatingWindow
      });
      toast.success("专家权限设置成功");
    } catch (error) {
      alert('保存设置失败');
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await api.getDistributionData(); // 假设有这样的API
        if (response.data) {
          setRoundOneDistribution(response.data.data.firstRoundDistribution);
          setRoundTwoDistribution(response.data.data.secondRoundDistribution);
          setShowFloatingWindow(response.data.data.showFloatingWindow);
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error);
        toast.error("无法加载设置");
      }
    };

    fetchSettings();
  }, []);

  return (
    <PageContainer>
      <ToastContainer />
      <h2>设置</h2>
      <h3>第一轮分布设置</h3>
      <table>
        <thead>
          <tr>
            <th>A</th>
            <th>B</th>
            <th>C</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {roundOneOrder.map(grade => (
              <td key={grade}>
                <input
                  type="number"
                  value={roundOneDistribution[grade]}
                  onChange={(e) => handleInputChange(e, 1, grade)}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>

      <h3>第二轮分布设置</h3>
      <table>
        <thead>
          <tr>
            <th>A+</th>
            <th>A</th>
            <th>B+</th>
            <th>B</th>
            <th>B-</th>
            <th>C</th>
            <th>C-</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {roundTwoOrder.map(grade => (
              <td key={grade}>
                <input
                  type="number"
                  value={roundTwoDistribution[grade]}
                  onChange={(e) => handleInputChange(e, 2, grade)}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>

      <div>
        <label>
          <input
            type="checkbox"
            checked={showFloatingWindow}
            onChange={toggleFloatingWindow}
          />
          显示悬浮窗口
        </label>
      </div>

      <button onClick={saveSettings}>保存设置</button>
    </PageContainer>
  );
};

export default SettingsPage;