import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom'; // 使用useLocation钩子
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import api from "../../api";

const Container = styled.div`
  padding: 20px;
  border: 2px dashed #ccc;  /* 添加虚线边框 */
  border-radius: 10px;      /* 可选：添加圆角 */
`;

const Title = styled.h2`
  text-align: center;
`;

const SubTitle = styled.h3`
  margin-top: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckboxGroup = styled.div`
  flex: 1;
  padding: 10px;
`;

const CheckboxLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-top: 10px;
`;

const GradeContainer = styled.div`
  margin-top: 20px;
`;

const GradeTitle = styled.p`
  margin-bottom: 10px;
`;

const GradeSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  margin-bottom: 10px;
`;

const List = styled.ul`
  margin-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
`;

const SubKindergardenReport = () => {
  const [assessmentAreas, setAssessmentAreas] = useState({
    direction: false,
    safety: false,
    education: false,
    environment: false,
    teachers: false
  });
  const [comments, setComments] = useState('');
  const [expertsGrade, setExpertsGrade] = useState('');
  const [expertsGrade2, setExpertsGrade2] = useState([]);
  //定义1个map，key为kindergartenId，value为surveys数组
  const [surveys, setSurveys] = useState([]);
  useEffect(() => {
    const fetchExpertsGrade2 = async () => {
      try {
        setSurveys([]);
        const response = await api.getAllExpertGrades2(2024);
        setExpertsGrade2(response.data);
        // console.log("所有专家的grade2评分:", response.data.data);
        response.data.data.forEach(expert => {
          // console.log(expert.evaluatorType);
          expert.grades2.forEach(grade => {
            // console.log(grade.kindergartens);
            grade.kindergartens.forEach(kindergarten => {
              // console.log(kindergarten.kindergartenId);
              //将kindergartenId作为key，surveys数组作为value，存入map
              setSurveys(prevSurveys => [...prevSurveys, ...kindergarten.surveys.map(survey => ({
                evaluatorType: expert.evaluatorType,
                kindergartenId: kindergarten.kindergartenId,
                surveyNumber: survey.surveyNumber,
                grades2: survey.grades2.grade
              }))]);
            });
          });
        });
      } catch (error) {
        console.error("获取专家grade2评分失败:", error);
        toast.error("获取专家评分数据失败");
      }
    };

    const fetchExpertsGrade = async () => {
      const response = await api.getAllQuantitativeGrades(2024);
      setExpertsGrade(response.data);
      console.log(response.data);
    } 


    fetchExpertsGrade2();
    fetchExpertsGrade();
  }, []);

  useEffect(() => {
    // console.log(surveys);
  }, [surveys]);

  useEffect(() => {
    if (surveys && Array.isArray(surveys)) {
      // 创建一个对象来存储结果
      const result = {};

      surveys.forEach((survey) => {
        const { evaluatorType, kindergartenId, surveyNumber, grades2 } = survey;

        // 仅处理符合以下 evaluatorType 的记录
        if ([
          '专家',
          '专家组研究生',
          '教育局-行政-人员',
          '教育局-教研-人员',
          '幼儿园园长（教师）'
        ].includes(evaluatorType)) {

          // 根据surveyNumber的十位数，决定分组
          const groupNumber = Math.floor(surveyNumber / 10);

          // 创建唯一的key来表示每个幼儿园和groupNumber的组合
          const key = `${kindergartenId}-${groupNumber}`;

          if (!result[key]) {
            result[key] = {
              kindergartenId,
              groupNumber,
              weightedSum: 0,
              count: 0,
            };
          }

          // 根据evaluatorType应用权重
          let weight = 0;
          if (evaluatorType === '组长') {
            weight = 0.6;
          } else if (evaluatorType === '专家组研究生') {
            weight = 0.2;
          } else if (evaluatorType === '教育局-行政-人员') {
            weight = 0.05;
          } else if (evaluatorType === '教育局-教研-人员') {
            weight = 0.05;
          } else if (evaluatorType === '幼儿园园长（教师）') {
            weight = 0.1;
          }

          // 计算加权得分，专家和研究生部分乘以0.8，其他直接使用
          let weightedGrade = grades2 * weight;
          if (evaluatorType === '专家' || evaluatorType === '专家组研究生') {
            weightedGrade *= 0.8;
          }

          // 累加加权得分
          result[key].weightedSum += weightedGrade;
          result[key].count += weight;
        }
      });

      // 创建最终数组，计算每个幼儿园每个groupNumber的加权平均得分
      const finalResult = Object.values(result).map((item) => ({
        kindergartenId: item.kindergartenId,
        groupNumber: item.groupNumber, // 例如1, 2, 3...
        weightedAverage: item.weightedSum / item.count, // 计算加权平均值
      }));

      // 输出处理后的数组
      console.log(finalResult);
    }
  }, [surveys]);

  const handleCheckboxChange = (area) => {
    setAssessmentAreas(prev => ({ ...prev, [area]: !prev[area] }));
  };

  const handleSubmit = () => {
    // 这里添加提交逻辑
    // console.log('提交报告', { assessmentAreas, comments, grade });
    toast.success('报告已成功提交！');
  };

  return (
    <Container>
      <Title>***幼儿园评估报告</Title>

      <Section>
        <SubTitle>一、评估背景与目的</SubTitle>
        <Paragraph>为了全面贯彻落实教育部《幼儿园保育教育质量评估指南》（2022年2月10日）的要求，由长春市教育局学前处发起，由长春市基础教育研究中心协同，由东北师范大学长春基础教育研究院负责设计和分析，开展了长春市2023年度幼儿园保教质量评估工作。评估内容包括幼儿园的办园方向、保育与安全、教育过程、环境创设和教师队伍等领域。</Paragraph>
        <Paragraph>包括***幼儿园在内的长春市30所幼儿园参与了2023年度幼儿园保教质量评估工作试点。</Paragraph>
      </Section>

      <Section>
        <SubTitle>二、评估方法与过程</SubTitle>
        <Paragraph>（一）评估对象</Paragraph>
        <Paragraph>***幼儿园。</Paragraph>

        <Paragraph>（二）评估内容</Paragraph>
        <Paragraph>坚持以促进幼儿身心健康发展为导向，聚焦幼儿园保育教育过程质量，评估内容主要包括办园方向、保育与安全、教育过程、环境创设、教师队伍等5个方面，共15项关键指标。</Paragraph>

        <List>
          <ListItem>1.办园方向：包括党建工作、品德启蒙和科学理念等3项关键指标。</ListItem>
          <ListItem>2.保育与安全：包括卫生保健、生活照料、安全防护等3项关键指标。</ListItem>
          <ListItem>3.教育过程：包括活动组织、师幼互动和家园共育等3项关键指标。</ListItem>
          <ListItem>4.环境创设：包括空间设施、玩具材料等2项关键指标。</ListItem>
          <ListItem>5.教师队伍：包括师德师风、人员配备、专业发展和激励机制等4项关键指标。</ListItem>
        </List>

        <Paragraph>（三）评估过程</Paragraph>
        <Paragraph>评估分为自评和专家入园两个阶段。</Paragraph>
        <Paragraph>第一个阶段是针对幼儿园提交的自评报告和佐证材料进行评估的自评阶段。该阶段邀请五组专家进行打分，赋分标准为优秀2分、达标1分、未发现达标证据0分、未达标-1分。</Paragraph>
        <Paragraph>第二阶段由6组专家入园检查，检查项目主要包括个别访谈、集体座谈、查阅资料、入班观察、实地测查。赋分标准为优秀2分，合格1分，不适宜0分，同时记录评估所需的积极证据和消极证据。各项评分结果转化为优秀、良好、及格、不及格4个等级；总评成绩则分为优秀、良好、及格、急需提高四个等级。</Paragraph>
      </Section>

      <Section>
        <SubTitle>三、园所自评和专家入园评估的量化结果</SubTitle>
        <Paragraph>在本次评估中，***幼儿园均完成了自评阶段和入园阶段的评估工作，从总体上看，该幼儿园保教质量评价总体水平为及格，具体评估结果如下。</Paragraph>
        <Paragraph>（一）15项指标评估结果</Paragraph>
        <Paragraph>15项指标自评阶段与专家入园考察阶段结果如表20-1，图20-1，图20-2。</Paragraph>
        {/* 这里可以添加表格和图表的组件 */}
      </Section>

      <ToastContainer />
    </Container>
  );
};

export default SubKindergardenReport;
