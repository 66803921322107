import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom'; // 使用useLocation钩子
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../../api"; // 假设你有一个API模块来处理数据请求

const Container = styled.div`
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 10px;
`;

const Title = styled.h2`
  text-align: center;
`;

const SubTitle = styled.h3`
  margin-top: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckboxGroup = styled.div`
  flex: 1;
  padding: 10px;
`;

const CheckboxLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-top: 10px;
`;

const GradeContainer = styled.div`
  margin-top: 20px;
`;

const GradeTitle = styled.p`
  margin-bottom: 10px;
`;

const GradeSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Evaluation83 = ({ onSurveyUpdate }) => {
    const location = useLocation();
    const [initialFormData, setInitialFormData] = useState(null);
    const [formData, setFormData] = useState({
        negative: Array(4).fill(0), // 假设有4个消极表现
        positive: Array(6).fill(0), // 假设有6个积极表现
        additionalComments1: '',
        additionalComments2: '',
        grade: '',
        surveyNumber: 83, // 添加问卷序号
        kindergartenId: '', // 添加幼儿园ID
        year: new Date().getFullYear() // 添加年份
    });
    const [currentExpertId, setCurrentExpertId] = useState('');
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const kindergartenId = params.get('kindergartenId');
        const expertId = params.get('expertId');
        setCurrentExpertId(expertId);

        setFormData(prevState => ({
            ...prevState,
            kindergartenId
        }));

        const fetchData = async () => {
            try {
                const response = await api.getExpertGrades2(expertId, formData.year, kindergartenId, formData.surveyNumber);
                if (response.data.success && response.data.data) {
                    setFormData(prevState => ({
                        ...prevState,
                        ...response.data.data
                    }));
                    setInitialFormData(response.data.data);
                } else {
                    setInitialFormData(formData);
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setInitialFormData(formData);
                } else {
                    console.error('Error fetching data:', error);
                }
            } finally {
                setIsDataLoaded(true);
            }
        };

        fetchData();
    }, [location]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (JSON.stringify(initialFormData) !== JSON.stringify(formData)) {
                const message = 'You have unsaved changes, do you really want to leave?';
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [initialFormData, formData]);

    const handleCheckboxChange = (e, type, index) => {
        const { checked } = e.target;
        setFormData(prevState => {
            const updatedList = [...prevState[type]];
            updatedList[index] = checked ? 1 : 0;

            return {
                ...prevState,
                [type]: updatedList
            };
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: name === 'grade' ? Number(value) : value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault(); 
        
        if (formData.grade === 0||formData.grade === '') {
            toast.error("您还没选择评级，请选择评级。");
            return;
        }

        const playload = {
            expertId: currentExpertId,
            formData: formData,
        };

        // 提交表单数据
        api.saveExpertGrades2(playload)
            .then(() => {
                // 更新 initialFormData
                setInitialFormData(formData);
                toast.success("保存成功");
                // 调用父组件的更新函数
                onSurveyUpdate(formData.surveyNumber);
            })
            .catch(error => {
                console.error('Error saving data:', error);
                toast.error("保存失败，请重试");
            });
    };

    if (!isDataLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <Container>
            <ToastContainer />
            <Title>考查要点 8.3</Title>
            <SubTitle>消极表现</SubTitle>
            <CheckboxContainer>
                <CheckboxGroup>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 0)}
                            checked={formData.negative[0] === 1}
                        />
                        教师不观察和参与幼儿的游戏，多是走来走去或教师之间闲聊。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 1)}
                            checked={formData.negative[1] === 1}
                        />
                        没有幼儿观察记录的文本。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 2)}
                            checked={formData.negative[2] === 1}
                        />
                        教师的介入未引起幼儿任何回应。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 3)}
                            checked={formData.negative[3] === 1}
                        />
                        教师的介入打断或终止了幼儿游戏。
                    </CheckboxLabel>
                </CheckboxGroup>
            </CheckboxContainer>

            <Textarea
                name="additionalComments1"
                value={formData.additionalComments1}
                onChange={handleChange}
                placeholder="如有其他证据，请写在下方："
            />

            <SubTitle>积极表现</SubTitle>
            <CheckboxContainer>
                <CheckboxGroup>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 0)}
                            checked={formData.positive[0] === 1}
                        />
                        一日生活中注意观察幼儿的健康状况和异常行为表现，了解原因并做好记录。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 1)}
                            checked={formData.positive[1] === 1}
                        />
                        观察幼儿在活动中表现出的兴趣和需要，有重点地进行观察和记录。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 2)}
                            checked={formData.positive[2] === 1}
                        />
                        在客观记录的基础上，通过信息化手段，对幼儿发展水平进行定期分析、评估，并调整教育行为。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 3)}
                            checked={formData.positive[3] === 1}
                        />
                        理解幼儿的想法，准确判断幼儿游戏与学习的需要，并提供有针对性地支持。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 4)}
                            checked={formData.positive[4] === 1}
                        />
                        教师鼓励和支持幼儿大胆表现、表达和想象创造。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 5)}
                            checked={formData.positive[5] === 1}
                        />
                        教师的介入能够引导幼儿思考、解决问题，丰富的扩展幼儿的经验。
                    </CheckboxLabel>
                </CheckboxGroup>
            </CheckboxContainer>

            <Textarea
                name="additionalComments2"
                value={formData.additionalComments2}
                onChange={handleChange}
                placeholder="如有其他证据，请写在下方："
            />

            <GradeContainer>
                <GradeTitle>
                请根据上述表现对该考查要点进行评级：
                </GradeTitle>
                <GradeSelect
                    name="grade"
                    value={formData.grade}
                    onChange={handleChange}
                >
                    <option value="">请选择评级</option>
                    <option value={1}>优秀</option>
                    <option value={2}>良好</option>
                    <option value={3}>及格</option>
                    <option value={4}>不及格</option>
                    <option value={5}>不了解</option>
                </GradeSelect>
            </GradeContainer>

            <Button onClick={handleSubmit}>保存</Button>
        </Container>
    );
};

export default Evaluation83;
