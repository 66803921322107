import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom'; // 使用useLocation钩子
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import api from "../../api";

const Container = styled.div`
  padding: 20px;
  border: 2px dashed #ccc;  /* 添加虚线边框 */
  border-radius: 10px;      /* 可选：添加圆角 */
`;

const Title = styled.h2`
  text-align: center;
`;

const SubTitle = styled.h3`
  margin-top: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckboxGroup = styled.div`
  flex: 1;
  padding: 10px;
`;

const CheckboxLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-top: 10px;
`;

const GradeContainer = styled.div`
  margin-top: 20px;
`;

const GradeTitle = styled.p`
  margin-bottom: 10px;
`;

const GradeSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Evaluation11 = ({ onSurveyUpdate }) => {
    const location = useLocation();
    const [initialFormData, setInitialFormData] = useState(null);
    const [formData, setFormData] = useState({
        negative: Array(4).fill(0), // 假设有4个消极表现
        positive: Array(10).fill(0), // 假设有10个积极表现
        additionalComments1: '',
        additionalComments2: '',
        grade: '',
        surveyNumber: 11, // 添加问卷序号
        kindergartenId: '', // 添加幼儿园ID
        year: new Date().getFullYear() // 添加年份
    });
    const [currentExpertId, setCurrentExpertId] = useState('');
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const kindergartenId = params.get('kindergartenId');
        const expertId = params.get('expertId');
        setCurrentExpertId(expertId);

        setFormData(prevState => ({
            ...prevState,
            kindergartenId
        }));

        console.log(expertId, formData.year, kindergartenId, formData.surveyNumber); 

        const fetchData = async () => {
            try {
                const response = await api.getExpertGrades2(expertId, formData.year, kindergartenId, formData.surveyNumber);
                if (response.data.success && response.data.data) {
                    setFormData(prevState => ({
                        ...prevState,
                        ...response.data.data
                    }));
                    setInitialFormData(response.data.data);
                } else {
                    setInitialFormData(formData);
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    // 处理404错误，保持表单为空
                    setInitialFormData(formData);
                } else {
                    console.error('Error fetching data:', error);
                }
            } finally {
                setIsDataLoaded(true);
            }
        };

        fetchData();
    }, [location]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (JSON.stringify(initialFormData) !== JSON.stringify(formData)) {
                const message = 'You have unsaved changes, do you really want to leave?';
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [initialFormData, formData]);

    const handleCheckboxChange = (e, type, index) => {
        const { checked } = e.target;
        setFormData(prevState => {
            const updatedList = [...prevState[type]];
            updatedList[index] = checked ? 1 : 0;

            return {
                ...prevState,
                [type]: updatedList
            };
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: name === 'grade' ? Number(value) : value // 将grade转换为数字
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault(); 
        
        if (formData.grade === 0||formData.grade === '') {
            toast.error("您还没选择评级，请选择评级。");
            return;
        }

        const playload = {
            expertId: currentExpertId,
            formData: formData,
        };

        // 提交表单数据
        api.saveExpertGrades2(playload)
            .then(() => {
                // 更新 initialFormData
                setInitialFormData(formData);
                toast.success("保存成功");
                // 调用父组件的更新函数
                onSurveyUpdate(formData.surveyNumber);
            })
            .catch(error => {
                console.error('Error saving data:', error);
                toast.error("保存失败，请重试");
            });
    };

    if (!isDataLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <Container>
            <ToastContainer />
            <Title>考查要点 1.1</Title>
            <SubTitle>消极表现</SubTitle>
            <CheckboxContainer>
                <CheckboxGroup>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 0)}
                            checked={formData.negative[0] === 1}
                        />
                        党组织对幼儿园工作领导的制度机制不健全。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 1)}
                            checked={formData.negative[1] === 1}
                        />
                        幼儿园园长负责制贯彻落实不到位。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 2)}
                            checked={formData.negative[2] === 1}
                        />
                        幼儿园领导班子成员分工及职责不明确，存在推诿扯皮现象。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'negative', 3)}
                            checked={formData.negative[3] === 1}
                        />
                        幼儿园党的工作未能融入到保育教育工作中。
                    </CheckboxLabel>
                </CheckboxGroup>
            </CheckboxContainer>

            <Textarea
                name="additionalComments1"
                value={formData.additionalComments1}
                onChange={handleChange}
                placeholder="如有其他证据，请写在下方："
            />

            <SubTitle>积极表现</SubTitle>
            <CheckboxContainer>
                <CheckboxGroup>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 0)}
                            checked={formData.positive[0] === 1}
                        />
                        幼儿园党组织有完善的党建工作制度。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 1)}
                            checked={formData.positive[1] === 1}
                        />
                        幼儿园党组织能够合理规划教师党员的发展工作。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 2)}
                            checked={formData.positive[2] === 1}
                        />
                        幼儿园党建活动内容丰富、形式多样。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 3)}
                            checked={formData.positive[3] === 1}
                        />
                        幼儿园党组织的班子成员与党员同志，能够主动联系并服务于教职员工。
                    </CheckboxLabel>
                </CheckboxGroup>
                <CheckboxGroup>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 4)}
                            checked={formData.positive[4] === 1}
                        />
                        幼儿园党建材料管理规范、保存完整。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 5)}
                            checked={formData.positive[5] === 1}
                        />
                        幼儿园实行园长负责制。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 6)}
                            checked={formData.positive[6] === 1}
                        />
                        幼儿园领导班子成员分工及职责明确。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 7)}
                            checked={formData.positive[7] === 1}
                        />
                        幼儿园领导班子成员间有较强的团队合作能力，已形成团队凝聚力。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 8)}
                            checked={formData.positive[8] === 1}
                        />
                        幼儿园领导班子成员能够认真听取党组织对幼儿园重大决策的意见。
                    </CheckboxLabel>
                    <CheckboxLabel>
                        <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'positive', 9)}
                            checked={formData.positive[9] === 1}
                        />
                        幼儿园以党建为引领，积极推进幼儿园保育教育工作。
                    </CheckboxLabel>
                </CheckboxGroup>
            </CheckboxContainer>

            <Textarea
                name="additionalComments2"
                value={formData.additionalComments2}
                onChange={handleChange}
                placeholder="如有其他证据，请写在下方："
            />

            <GradeContainer>
                <GradeTitle>
                请根据上述表现对该考查要点进行评级：
                </GradeTitle>
                <GradeSelect
                    name="grade"
                    value={formData.grade}
                    onChange={handleChange}
                >
                    <option value="">请选择评级</option>
                    <option value={1}>优秀</option>
                    <option value={2}>良好</option>
                    <option value={3}>及格</option>
                    <option value={4}>不及格</option>
                    <option value={5}>不了解</option>
                </GradeSelect>
            </GradeContainer>
            <Button onClick={handleSubmit}>保存</Button>
        </Container>
    );
};

export default Evaluation11;
