import plupload from 'plupload';
let accessid = ''
let accesskey = ''
let host = ''
let policyBase64 = ''
let signature = ''
let callbackbody = ''
let filename = ''
let key = ''
let expire = 0
let g_object_name = ''
let g_object_name_type = ''
let timestamp = Date.parse(new Date()) / 1000;
let now = timestamp

function send_request() {
    var xmlhttp = null;
    if (window.XMLHttpRequest) {
        xmlhttp = new XMLHttpRequest();
    }
    // else if (window.ActiveXObject) {
    //     xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
    // }

    if (xmlhttp != null) {
        // serverUrl是 用户获取 '签名和Policy' 等信息的应用服务器的URL，请将下面的IP和Port配置为您自己的真实信息。
        const serverUrl = 'https://changchunbaojiao.com/api/Signature'

        xmlhttp.open("GET", serverUrl, false);
        xmlhttp.send(null);
        return xmlhttp.responseText
    }
    else {
        alert("Your browser does not support XMLHTTP.");
    }
};

function check_object_radio() {
    var tt = document.getElementsByName('myradio');
    for (var i = 0; i < tt.length; i++) {
        if (tt[i].checked) {
            g_object_name_type = tt[i].value;
            break;
        }
    }
}

function get_signature() {
    // 可以判断当前expire是否超过了当前时间， 如果超过了当前时间， 就重新取一下，3s 作为缓冲。
    now = timestamp = Date.parse(new Date()) / 1000;
    if (expire < now + 3) {
        let body = send_request()
        var obj = eval("(" + body + ")");
        host = obj['host']
        policyBase64 = obj['policy']
        accessid = obj['accessid']
        signature = obj['signature']
        expire = parseInt(obj['expire'])
        // callbackbody = obj['callback']
        key = obj['dir']
        return true;
    }
    return false;
};

function random_string(len) {
    len = len || 32;
    var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    var maxPos = chars.length;
    var pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}

function get_suffix(filename) {
    let pos = filename.lastIndexOf('.')
    let suffix = ''
    if (pos != -1) {
        suffix = filename.substring(pos)
    }
    return suffix;
}

function calculate_object_name(filename) {
    if (g_object_name_type == 'local_name') {
        g_object_name += "${filename}"
    }
    else if (g_object_name_type == 'random_name') {
        let suffix = get_suffix(filename)
        g_object_name = key + random_string(10) + suffix
    }
    return ''
}

function get_uploaded_object_name(filename) {
    if (g_object_name_type == 'local_name') {
        let tmp_name = g_object_name
        tmp_name = tmp_name.replace("${filename}", filename);
        return tmp_name
    }
    else if (g_object_name_type == 'random_name') {
        return g_object_name
    }
}

function set_upload_param(up, filename, ret, subDir) {
    if (ret == false) {
        ret = get_signature()
    }
    g_object_name = key + subDir;

    if (filename != '') {
        let suffix = get_suffix(filename)
        calculate_object_name(filename)
    }
    let new_multipart_params = {
        'key': g_object_name,
        'policy': policyBase64,
        'OSSAccessKeyId': accessid,
        'success_action_status': '200', //让服务端返回200,不然，默认会返回204
        // 'callback': callbackbody,
        'signature': signature,
    };
    up.setOption({
        'url': host,
        'multipart_params': new_multipart_params
    });

    up.start();
}
export const initOssUpload = (browseButtonId, containerId, itemid, fileNamePrifix, setUploadedFilesCallback, setAllFilesUploadedCallback) => {
    let itemId = itemid;
    var uploader = new plupload.Uploader({
        runtimes: 'html5,flash,silverlight,html4',
        //browse_button: 'selectfiles',
        //multi_selection: false,
        //container: document.getElementById('container'),
        browse_button: browseButtonId, // 使用参数化的按钮ID
        container: document.getElementById(containerId), // 使用参数化的容器ID
        flash_swf_url: 'lib/plupload-2.1.2/js/Moxie.swf',
        silverlight_xap_url: 'lib/plupload-2.1.2/js/Moxie.xap',
        url: 'https://oss.aliyuncs.com',

        filters: {
            mime_types: [ // 只允许上传图片、Word、PPT、PDF文件
                { title: "Image files", extensions: "jpg,jpeg,png,gif" },
                { title: "Document files", extensions: "doc,docx,ppt,pptx,pdf" },
                { title: "Video files", extensions: "mp4,avi,mov,wmv" }
            ],
            max_file_size: '60mb', //最大只能上传10mb的文件
            prevent_duplicates: true //不允许选取重复文件
        },

        init: {
            PostInit: function () {
                // document.getElementById('ossfile-' + itemid).innerHTML = '';
                document.getElementById('postfiles-' + itemid).onclick = function () {
                    set_upload_param(uploader, '', false);
                    return false;
                };
            },

            FilesAdded: function (up, files) {
                // 检查文件数量，如果超过20个，提示用户并删除多余的文件
                if (up.files.length > 20) {
                    alert("最多只能上传20个文件，请重新选择。");
                    plupload.each(files, function (file) {
                        up.removeFile(file);
                    });
                    return; // 终止后续文件的添加处理
                }


                plupload.each(files, function (file) {
                    document.getElementById('ossfile-' + itemid).innerHTML += '<div id="' + file.id + '">' + file.name + ' (' + plupload.formatSize(file.size) + ')<b></b>'
                        + '<div class="progress"><div class="progress-bar" style="width: 0%"></div></div>'
                        + '</div>';
                });
            },

            // FilesAdded: function (up, files) {
            //     plupload.each(files, function (file) {
            //         const fileContainer = document.getElementById('ossfile-' + itemid);
            //         const fileEntry = document.createElement('div');
            //         fileEntry.id = file.id;
            //         fileEntry.className = 'file-entry';

            //         const fileName = document.createElement('span');
            //         fileName.textContent = file.name + ' (' + plupload.formatSize(file.size) + ')';

            //         const progressBarContainer = document.createElement('div');
            //         progressBarContainer.className = 'progress';

            //         const progressBar = document.createElement('div');
            //         progressBar.className = 'progress-bar';
            //         progressBar.style.width = '0%';

            //         const deleteButton = document.createElement('button');
            //         deleteButton.textContent = '删除';
            //         deleteButton.type = 'button';
            //         deleteButton.onclick = function () {
            //             handleRemoveFile(itemid, file.name); // 需要确保这个函数能够被正确调用
            //         };

            //         progressBarContainer.appendChild(progressBar);
            //         fileEntry.appendChild(fileName);
            //         fileEntry.appendChild(progressBarContainer);
            //         fileEntry.appendChild(deleteButton);

            //         fileContainer.appendChild(fileEntry);
            //     });
            // },


            // UploadProgress: function (up, file) {
            //     var fileElement = document.getElementById(file.id);
            //     if (fileElement) {
            //         var progBar = fileElement.querySelector('.progress-bar');
            //         if (progBar) {
            //             progBar.style.width = file.percent + '%';
            //         }
            //     }
            // },

            BeforeUpload: function (up, file) {
                // check_object_radio();
                g_object_name_type = 'local_name'
                set_upload_param(up, fileNamePrifix + '_' + file.name, true, fileNamePrifix + '/');
            },

            UploadProgress: function (up, file) {
                var d = document.getElementById(file.id);
                d.getElementsByTagName('b')[0].innerHTML = '<span>' + file.percent + "%</span>";
                var prog = d.getElementsByTagName('div')[0];
                var progBar = prog.getElementsByTagName('div')[0]
                progBar.style.width = file.percent + '%';
                progBar.setAttribute('aria-valuenow', file.percent);
            },

            FileUploaded: function (up, file, info) {
                if (info.status == 200) {
                    const uploadedFileName = get_uploaded_object_name(file.name); // 这是上传后的文件名
                    // 使用传递的 setUploadedFilesCallback 更新状态
                    setUploadedFilesCallback(prevState => {
                        // 构建新的状态对象
                        const newState = {
                            ...prevState,
                            [itemId]: [
                                ...(prevState[itemId] || []),
                                {
                                    name: uploadedFileName,
                                    // 可以添加更多属性，比如文件URL等
                                }
                            ]
                        };
                        // 返回新状态以更新
                        return newState;
                    });
                    // document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = 'upload to oss success, object name:' + get_uploaded_object_name(file.name) + ' 回调服务器返回的内容是:' + info.response;
                    document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = '上传到成功';
                }
                else if (info.status == 203) {
                    document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = '上传到OSS成功，但是oss访问用户设置的上传回调服务器失败，失败原因是:' + info.response;
                }
                else {
                    document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = info.response;
                }

                // 检查是否所有文件都已上传完毕
                var uploaded = up.files.length > 0 && up.files.every(file => file.status === plupload.DONE);

                // 如果所有文件都已上传，自动点击保存按钮
                if (uploaded) {
                    setAllFilesUploadedCallback(true)
                }
            },

            Error: function (up, err) {
                // if (err.code == -600) {
                //     document.getElementById('console').appendChild(document.createTextNode("\n选择的文件太大了,可以根据应用情况，在upload.js 设置一下上传的最大大小"));
                // }
                // else if (err.code == -601) {
                //     document.getElementById('console').appendChild(document.createTextNode("\n选择的文件后缀不对,可以根据应用情况，在upload.js进行设置可允许的上传文件类型"));
                // }
                // else if (err.code == -602) {
                //     document.getElementById('console').appendChild(document.createTextNode("\n这个文件已经上传过一遍了"));
                // }
                // else {
                //     document.getElementById('console').appendChild(document.createTextNode("\nError xml:" + err.response));
                // }
            }
        }
    });

    uploader.init();
};