import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../../api"; // 假设您有一个api模块处理数据请求

const Container = styled.div`
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  text-align: center;
`;

const SubTitle = styled.h3`
  margin-top: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckboxGroup = styled.div`
  flex: 1;
  padding: 10px;
`;

const CheckboxLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-top: 10px;
`;

const GradeSelectContainer = styled.div`
  margin-top: 20px;
`;

const GradeSelectLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const GradeSelect = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const ErrorMsg = styled.div`
  color: red;
  margin-top: 10px;
`;

const SuccessMsg = styled.div`
  color: green;
  margin-top: 10px;
`;

const Evaluation151 = ({ onSurveyUpdate }) => {
  const location = useLocation();
  const [initialFormData, setInitialFormData] = useState(null);
  const [formData, setFormData] = useState({
    negative: Array(3).fill(0),
    positive: Array(5).fill(0),
    additionalComments1: '',
    additionalComments2: '',
    grade: '',
    surveyNumber: 151,
    kindergartenId: '',
    year: new Date().getFullYear(),
  });

  const [currentExpertId, setCurrentExpertId] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const kindergartenId = params.get('kindergartenId');
    const expertId = params.get('expertId');
    setCurrentExpertId(expertId);

    setFormData((prevState) => ({
      ...prevState,
      kindergartenId,
    }));

    const fetchData = async () => {
      try {
        const response = await api.getExpertGrades2(
          expertId,
          formData.year,
          kindergartenId,
          formData.surveyNumber
        );
        if (response.data.success && response.data.data) {
          setFormData((prevState) => ({
            ...prevState,
            ...response.data.data,
          }));
          setInitialFormData(response.data.data);
        } else {
          setInitialFormData(formData);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setInitialFormData(formData);
        } else {
          console.error('Error fetching data:', error);
        }
      } finally {
        setIsDataLoaded(true);
      }
    };

    fetchData();
  }, [location]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (JSON.stringify(initialFormData) !== JSON.stringify(formData)) {
        const message = 'You have unsaved changes, do you really want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [initialFormData, formData]);

  const handleCheckboxChange = (e, type, index) => {
    const { checked } = e.target;
    setFormData((prevState) => {
      const updatedList = [...prevState[type]];
      updatedList[index] = checked ? 1 : 0;
      return { ...prevState, [type]: updatedList };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === 'grade' ? Number(value) : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    
    if (formData.grade === 0||formData.grade === '') {
        toast.error("您还没选择评级，请选择评级。");
        return;
    }

    const playload = {
        expertId: currentExpertId,
        formData: formData,
    };

    // 提交表单数据
    api.saveExpertGrades2(playload)
        .then(() => {
            // 更新 initialFormData
            setInitialFormData(formData);
            toast.success("保存成功");
            // 调用父组件的更新函数
            onSurveyUpdate(formData.surveyNumber);
        })
        .catch(error => {
            console.error('Error saving data:', error);
            toast.error("保存失败，请重试");
        });
};

  if (!isDataLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <ToastContainer />
      <Title>考查要点 15.1 观测指标记录与评等级表</Title>

      <CheckboxContainer>
        <CheckboxGroup>
          <SubTitle>消极表现</SubTitle>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 0)}
              checked={formData.negative[0] === 1}
            />
            幼儿园没有教师激励制度或措施。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 1)}
              checked={formData.negative[1] === 1}
            />
            幼儿园的教师激励制度存在唯课题、唯论文的倾向。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 2)}
              checked={formData.negative[2] === 1}
            />
            幼儿园的教师激励方式单一。
          </CheckboxLabel>
        </CheckboxGroup>
      </CheckboxContainer>

      <Textarea
        name="additionalComments1"
        value={formData.additionalComments1}
        onChange={handleChange}
        placeholder="如有其他证据，请写在下方："
        rows="4"
        style={{ width: '100%' }}
      />

      <CheckboxContainer>
        <CheckboxGroup>
          <SubTitle>积极表现</SubTitle>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 0)}
              checked={formData.positive[0] === 1}
            />
            幼儿园具有教师激励制度，并能够执行。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 1)}
              checked={formData.positive[1] === 1}
            />
            幼儿园依据教师的个别化发展需求，制订并实施有针对性的教师激励措施。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 2)}
              checked={formData.positive[2] === 1}
            />
            幼儿园把认真履行教育教学职责作为评价教师的基本要求。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 3)}
              checked={formData.positive[3] === 1}
            />
            幼儿园把以游戏为基本活动促进儿童主动学习和全面发展的能力作为评价教师的关键指标。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 4)}
              checked={formData.positive[4] === 1}
            />
            幼儿园的教师激励方式多样。
          </CheckboxLabel>
        </CheckboxGroup>
      </CheckboxContainer>

      <Textarea
        name="additionalComments2"
        value={formData.additionalComments2}
        onChange={handleChange}
        placeholder="如有其他证据，请写在下方："
        rows="4"
        style={{ width: '100%' }}
      />

      <GradeSelectContainer>
        <GradeSelectLabel>请根据上述表现对该考查要点进行评级：</GradeSelectLabel>
        <GradeSelect
          name="grade"

          value={formData.grade}
          onChange={handleChange}
        >
          <option value="">请选择评级</option>
          <option value={1}>优秀</option>
          <option value={2}>良好</option>
          <option value={3}>及格</option>
          <option value={4}>不及格</option>
          <option value={5}>不了解</option>
        </GradeSelect>
      </GradeSelectContainer>

      {error && <ErrorMsg>{error}</ErrorMsg>}
      {success && <SuccessMsg>{success}</SuccessMsg>}

      <Button onClick={handleSubmit}>保存</Button>
    </Container>
  );
};

export default Evaluation151;
