import React, { useState, useEffect } from 'react';
import api from '../api';
import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const PageContainer = styled.div`
  padding: 20px;
`;

const FixedTopSection = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
  z-index: 1000;
`;

const TableContainer = styled.div`
  margin-top: 60px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
`;

const Th = styled.th`
  padding: 10px;
  text-align: left;
  background-color: #4CAF50;
  color: white;
  border-bottom: 1px solid #ddd;
`;

const Td = styled.td`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  background-color: #f9f9f9;
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const EditInput = styled.input`
  padding: 5px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 95%;
`;

const ButtonTable = styled.button`
  padding: 5px 10px;
  margin-right: 5px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Select = styled.select`
  padding: 5px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: calc(100% - 10px); /* 调整宽度以适应边距 */
`;

const Button = styled.button`
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  background-color: #4CAF50; /* Green */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const Circle = styled.div`
  width: 30px;
  height: 30px;
  margin: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ddd;
  color: #333;
  cursor: pointer;
  &.selected {
    background-color: #4CAF50;
    color: white;
  }
`;

const UserInfoContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: right;
  font-size: 18px;
  color: #3d3d3d;
  z-index: 1000;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SecondStageSettingsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [experts, setExperts] = useState([]);
  const [kindergartens, setKindergartens] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingPasswordIndex, setEditingPasswordIndex] = useState(null);
  const [password, setPassword] = useState("");
  let { expertId, realName } = location.state || {};

  useEffect(() => {
    const fetchExperts = async () => {
      try {
        const response = await api.getExperts();
        setExperts(response.data.data);
      } catch (error) {
        console.error("Failed to fetch experts:", error);
      }
    };

    const fetchKindergartens = async () => {
      try {
        const response = await api.getKindergartens();
        setKindergartens(response.data.data);
      } catch (error) {
        console.error("Failed to fetch kindergartens:", error);
      }
    };

    fetchExperts();
    fetchKindergartens();
  }, []);

  const handleEdit = (index) => {
    const newExperts = [...experts];
    newExperts[index].isEditing = true;
    newExperts[index].editRealName = newExperts[index].realName;
    newExperts[index].editEmail = newExperts[index].email;
    newExperts[index].editRole = newExperts[index].role;
    setExperts(newExperts);
    setEditingIndex(index);
  };

  const handleCancelEdit = (index) => {
    const newExperts = [...experts];
    newExperts[index].isEditing = false;
    setExperts(newExperts);
    setEditingIndex(null);
  };

  const handleChange = (index, field, value) => {
    const newExperts = [...experts];
    newExperts[index][field] = value;
    setExperts(newExperts);
  };

  const handleSave = async (index) => {
    const expert = experts[index];
    try {
      await api.updateExpertById({
        id: expert._id,
        realName: expert.editRealName,
        email: expert.editEmail,
        role: expert.editRole
      });
      const newExperts = [...experts];
      newExperts[index].realName = expert.editRealName;
      newExperts[index].email = expert.editEmail;
      newExperts[index].role = expert.editRole;
      newExperts[index].isEditing = false;
      setExperts(newExperts);
      setEditingIndex(null);
      toast.success("保存成功");
    } catch (error) {
      console.error("Failed to save expert changes:", error);
    }
  };

  const handleSavePassword = async (expertId, index) => {
    if (!password) {
      alert("请输入密码");
      return;
    }
    try {
      await api.updateExpertPassword({ id: expertId, newPassword: password });
      alert("密码更新成功");
      setPassword("");
      setEditingPasswordIndex(null);
    } catch (error) {
      console.error("更新密码失败:", error);
      alert("密码更新失败");
    }
  };

  const handleLogout = () => {
    navigate('/login');
  };

  const handleToggleKindergarten = (expertIndex, kindergartenId) => {
    const newExperts = [...experts];
    const assignedKindergartens = newExperts[expertIndex].assignedKindergartens || [];

    if (assignedKindergartens.includes(kindergartenId)) {
      newExperts[expertIndex].assignedKindergartens = assignedKindergartens.filter(id => id !== kindergartenId);
    } else {
      newExperts[expertIndex].assignedKindergartens = [...assignedKindergartens, kindergartenId];
    }

    setExperts(newExperts);
  };

  const handleSaveAssignedKindergartens = async (expertId, index) => {
    const expert = experts[index];
    console.log("expert.assignedKindergartens:", expert.assignedKindergartens);
    try {
      await api.updateExpertSettings2({
        id: expertId,
        assignedKindergartens: expert.assignedKindergartens,
      });
      toast.success("专家幼儿园设置成功");
    } catch (error) {
      console.error("专家幼儿园设置失败:", error);
      toast.error("专家幼儿园设置失败");
    }
  };

  return (
    <PageContainer>
      <ToastContainer />
      <UserInfoContainer>
        {realName}
        <button onClick={handleLogout} style={{ marginLeft: '20px', padding: '5px 10px', fontSize: '16px', cursor: 'pointer', borderRadius: '5px', border: 'none', backgroundColor: '#6976d9', color: 'white' }}>
          登出
        </button>
      </UserInfoContainer>
      <h1>阶段二专家管理</h1>
      <FixedTopSection>
        <h2>幼儿园编号</h2>
        <InlineContainer>
          {kindergartens.map((kg, index) => (
            <span key={index} style={{ marginRight: '10px' }}>{kg._id}</span>
          ))}
        </InlineContainer>
      </FixedTopSection>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <Th>姓名</Th>
              <Th>电子邮件</Th>
              <Th>角色</Th>
              <Th>操作</Th>
              <Th>幼儿园权限</Th>
              <Th>保存</Th>
            </tr>
          </thead>
          <tbody>
            {experts.map((expert, index) => (
              <tr key={expert._id}>
                <Td>
                  {expert.isEditing ? (
                    <EditInput
                      type="text"
                      value={expert.editRealName}
                      onChange={(e) => handleChange(index, 'editRealName', e.target.value)}
                    />
                  ) : (
                    expert.realName
                  )}
                </Td>
                <Td>{expert.email}</Td>
                <Td>
                  {expert.isEditing ? (
                    <Select
                      value={expert.editRole}
                      onChange={(e) => handleChange(index, 'editRole', e.target.value)}
                    >
                      <option value="guest">游客</option>
                      <option value="expert">专家</option>
                    </Select>
                  ) : (
                    expert.role === 'guest' ? '游客' : '专家'
                  )}
                </Td>
                <Td>
                  <ButtonGroup>
                    {expert.isEditing ? (
                      <>
                        <ButtonTable onClick={() => handleSave(index)}>保存</ButtonTable>
                        <ButtonTable onClick={() => handleCancelEdit(index)}>取消</ButtonTable>
                      </>
                    ) : (
                      <>
                        <ButtonTable onClick={() => handleEdit(index)}>编辑</ButtonTable>
                        {index === editingPasswordIndex ? (
                          <InlineContainer>
                            <EditInput
                              type="password"
                              placeholder="新密码"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <ButtonTable onClick={() => handleSavePassword(expert._id, index)}>
                              保存密码
                            </ButtonTable>
                          </InlineContainer>
                        ) : (
                          <ButtonTable onClick={() => setEditingPasswordIndex(index)}>
                            修改密码
                          </ButtonTable>
                        )}
                      </>
                    )}
                  </ButtonGroup>
                </Td>
                <Td>
                  {kindergartens.map((kg) => (
                    <Circle
                      key={kg._id}
                      className={expert.assignedKindergartens?.includes(kg._id) ? 'selected' : ''}
                      onClick={() => handleToggleKindergarten(index, kg._id)}
                    >
                      {kg.name}
                    </Circle>
                  ))}
                </Td>
                <Td>
                  <Button onClick={() => handleSaveAssignedKindergartens(expert._id, index)}>保存</Button>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </PageContainer>
  );
};

export default SecondStageSettingsPage;
