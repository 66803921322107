import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../../api";

const Container = styled.div`
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 10px;
`;

const Title = styled.h2`
  text-align: center;
`;

const SubTitle = styled.h3`
  margin-top: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckboxGroup = styled.div`
  flex: 1;
  padding: 10px;
`;

const CheckboxLabel = styled.label`
  display: block;
  margin-bottom: 10px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-top: 10px;
`;

const GradeContainer = styled.div`
  margin-top: 20px;
`;

const GradeTitle = styled.h3`
  margin-bottom: 10px;
`;

const GradeSelect = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const Evaluation32 = ({ onSurveyUpdate }) => {
    const location = useLocation();
    const [initialFormData, setInitialFormData] = useState(null);
    const [formData, setFormData] = useState({
        negative: Array(9).fill(0), // 假设有9个消极表现
        positive: Array(11).fill(0), // 假设有11个积极表现
        additionalComments1: '',
        additionalComments2: '',
        grade: '',
        surveyNumber: 32, // 添加问卷序号
        kindergartenId: '', // 添加幼儿园ID
        year: new Date().getFullYear() // 添加年份
    });
    const [currentExpertId, setCurrentExpertId] = useState('');
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const kindergartenId = params.get('kindergartenId');
        const expertId = params.get('expertId');
        setCurrentExpertId(expertId);

        setFormData(prevState => ({
            ...prevState,
            kindergartenId
        }));

        const fetchData = async () => {
            try {
                const response = await api.getExpertGrades2(expertId, formData.year, kindergartenId, formData.surveyNumber);
                if (response.data.success && response.data.data) {
                    setFormData(prevState => ({
                        ...prevState,
                        ...response.data.data
                    }));
                    setInitialFormData(response.data.data);
                } else {
                    setInitialFormData(formData);
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    // 处理404错误，保持表单为空
                    setInitialFormData(formData);
                } else {
                    console.error('Error fetching data:', error);
                }
            } finally {
                setIsDataLoaded(true);
            }
        };

        fetchData();
    }, [location]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (JSON.stringify(initialFormData) !== JSON.stringify(formData)) {
                const message = 'You have unsaved changes, do you really want to leave?';
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [initialFormData, formData]);

    const handleCheckboxChange = (e, type, index) => {
        const { checked } = e.target;
        setFormData(prevState => {
            const updatedList = [...prevState[type]];
            updatedList[index] = checked ? 1 : 0;

            return {
                ...prevState,
                [type]: updatedList
            };
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: name === 'grade' ? Number(value) : value // 将grade转换为数字
        }));
    };

    const handleSubmit = (e) => {
      e.preventDefault(); 
      
      if (formData.grade === 0||formData.grade === '') {
          toast.error("您还没选择评级，请选择评级。");
          return;
      }

      const playload = {
          expertId: currentExpertId,
          formData: formData,
      };

      // 提交表单数据
      api.saveExpertGrades2(playload)
          .then(() => {
              // 更新 initialFormData
              setInitialFormData(formData);
              toast.success("保存成功");
              // 调用父组件的更新函数
              onSurveyUpdate(formData.surveyNumber);
          })
          .catch(error => {
              console.error('Error saving data:', error);
              toast.error("保存失败，请重试");
          });
  };

    if (!isDataLoaded) {
        return <div>Loading...</div>;
    }

  return (
    <Container>
      <ToastContainer />
      <Title>考查要点 3.2</Title>
      <SubTitle>消极表现</SubTitle>
      <CheckboxContainer>
        <CheckboxGroup>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 0)}
              checked={formData.negative[0] === 1}
            />
            活动安排以统筹规划为主。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 1)}
              checked={formData.negative[1] === 1}
            />
            课程内容和课程设计符合幼儿年龄阶段特征和发展需要。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 2)}
              checked={formData.negative[2] === 1}
            />
            提供的玩具和材料数量、种类难以满足幼儿活动的基本需求。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 3)}
              checked={formData.negative[3] === 1}
            />
            室内外空间环境的与幼儿实际需求不符合。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 4)}
              checked={formData.negative[4] === 1}
            />
            社会资源利用率低，如一学期只有1-2次组织幼儿到园外开展社会实践活动。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 5)}
              checked={formData.negative[5] === 1}
            />
            要求幼儿掌握声母、韵母、能拼读书写。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 6)}
              checked={formData.negative[6] === 1}
            />
            要求幼儿背乘法口诀，做100以内的加减法。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 7)}
              checked={formData.negative[7] === 1}
            />
            要求幼儿每天背诵古诗。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'negative', 8)}
              checked={formData.negative[8] === 1}
            />
            特色课程的内容难度偏大。
          </CheckboxLabel>
        </CheckboxGroup>
      </CheckboxContainer>

      <Textarea
        name="additionalComments1"
        value={formData.additionalComments1}
        onChange={handleChange}
        placeholder="如有其他证据，请写在下方："
      />

      <SubTitle>积极表现</SubTitle>
      <CheckboxContainer>
        <CheckboxGroup>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 0)}
              checked={formData.positive[0] === 1}
            />
            活动内容尊重幼儿兴趣，强调幼儿自主发现问题、解决问题，并为幼儿提供多种资源。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 1)}
              checked={formData.positive[1] === 1}
            />
            创设多种机会，让幼儿根据自己的需求开展探索活动，并提供适宜的支持。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 2)}
              checked={formData.positive[2] === 1}
            />
            在活动中鼓励幼儿根据自己的兴趣和需要探索材料。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 3)}
              checked={formData.positive[3] === 1}
            />
            能够根据幼儿需要调整空间布局，投放材料可以体现开放性，支持幼儿有效使用。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 4)}
              checked={formData.positive[4] === 1}
            />
            提供的教具和材料丰富且具有可操作性，能适合不同活动的需要。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 5)}
              checked={formData.positive[5] === 1}
            />
            充分利用本土的资源、文化等丰富幼儿经验。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 6)}
              checked={formData.positive[6] === 1}
            />
            带领幼儿参观博物馆、科技馆等活动。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 7)}
              checked={formData.positive[7] === 1}
            />
            课程内容和课程设计符合幼儿年龄阶段特征和发展需要。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 8)}
              checked={formData.positive[8] === 1}
            />
            坚持一日活动皆是教育，在日常生活、各种游戏中激发幼儿学习。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 9)}
              checked={formData.positive[9] === 1}
            />
            课程内容不仅关注认知发展，同时关注幼儿社会性、情感和学习品质的发展。
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, 'positive', 10)}
              checked={formData.positive[10] === 1}
            />
            根据幼儿园实际条件（师资、空间环境等）开展特色课程。
          </CheckboxLabel>
        </CheckboxGroup>
      </CheckboxContainer>

      <Textarea
        name="additionalComments2"
        value={formData.additionalComments2}
        onChange={handleChange}
        placeholder="如有其他证据，请写在下方："
      />

      <GradeContainer>
        <GradeTitle>请根据上述表现对该考查要点进行评级：</GradeTitle>
        <GradeSelect
          name="grade"

          value={formData.grade}
          onChange={handleChange}
        >
        <option value="">请选择评级</option>
        <option value={1}>优秀</option>
        <option value={2}>良好</option>
        <option value={3}>及格</option>
        <option value={4}>不及格</option>
        <option value={5}>不了解</option>
                </GradeSelect>

            </GradeContainer>


      <Button onClick={handleSubmit}>保存</Button>
    </Container>
  );
};

export default Evaluation32;
