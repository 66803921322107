import React, { useState } from 'react';
import styled from 'styled-components';
import api from '../api'; // 根据你的项目结构导入API调用方法
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;

  form {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
`;

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
        email,        
      };
    try {    
      
      await api.resetPasswordRequest(payload);
      alert('重置密码邮件已发送，请检查您的邮箱。');
      toast.success('重置密码邮件已发送，请检查您的邮箱。', { autoClose: 500 });
      // 重置成功后，可能需要导航到登录页面或其他页面
      navigate('/login');
    } catch (error) {
      toast.error('请求失败，请稍后再试。', { autoClose: 500 });
      console.error('Forgot password error:', error);
    }
  };

  return (
    <ForgotPasswordContainer>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <h2>忘记密码</h2>
        <input
          type="email"
          placeholder="请输入您的邮箱地址"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">发送重置密码邮件</button>
      </form>
    </ForgotPasswordContainer>
  );
};

export default ForgotPassword;
