import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import api from '../api';
import styled from 'styled-components';
import Evaluation11 from './Evaluation/Evaluation11';
import Evaluation12 from './Evaluation/Evaluation12';
import Evaluation13 from './Evaluation/Evaluation13';
import Evaluation21 from './Evaluation/Evaluation21';
import Evaluation22 from './Evaluation/Evaluation22';
import Evaluation31 from './Evaluation/Evaluation31';
import Evaluation32 from './Evaluation/Evaluation32';
import Evaluation41 from './Evaluation/Evaluation41';
import Evaluation42 from './Evaluation/Evaluation42';
import Evaluation43 from './Evaluation/Evaluation43';
import Evaluation44 from './Evaluation/Evaluation44';
import Evaluation51 from './Evaluation/Evaluation51';
import Evaluation52 from './Evaluation/Evaluation52';
import Evaluation53 from './Evaluation/Evaluation53';
import Evaluation54 from './Evaluation/Evaluation54';
import Evaluation61 from './Evaluation/Evaluation61';
import Evaluation62 from './Evaluation/Evaluation62';
import Evaluation63 from './Evaluation/Evaluation63';
import Evaluation71 from './Evaluation/Evaluation71';
import Evaluation72 from './Evaluation/Evaluation72';
import Evaluation73 from './Evaluation/Evaluation73';
import Evaluation74 from './Evaluation/Evaluation74';
import Evaluation75 from './Evaluation/Evaluation75';
import Evaluation76 from './Evaluation/Evaluation76';
import Evaluation81 from './Evaluation/Evaluation81';
import Evaluation82 from './Evaluation/Evaluation82';
import Evaluation83 from './Evaluation/Evaluation83';
import Evaluation84 from './Evaluation/Evaluation84';
import Evaluation85 from './Evaluation/Evaluation85';
import Evaluation86 from './Evaluation/Evaluation86';
import Evaluation87 from './Evaluation/Evaluation87';
import Evaluation91 from './Evaluation/Evaluation91';
import Evaluation92 from './Evaluation/Evaluation92';
import Evaluation93 from './Evaluation/Evaluation93';
import Evaluation94 from './Evaluation/Evaluation94';
import Evaluation101 from './Evaluation/Evaluation101';
import Evaluation102 from './Evaluation/Evaluation102';
import Evaluation111 from './Evaluation/Evaluation111';
import Evaluation112 from './Evaluation/Evaluation112';
import Evaluation121 from './Evaluation/Evaluation121';
import Evaluation122 from './Evaluation/Evaluation122';
import Evaluation131 from './Evaluation/Evaluation131';
import Evaluation132 from './Evaluation/Evaluation132';
import Evaluation141 from './Evaluation/Evaluation141';
import Evaluation142 from './Evaluation/Evaluation142';
import Evaluation143 from './Evaluation/Evaluation143';
import Evaluation151 from './Evaluation/Evaluation151';
import Evaluation152 from './Evaluation/Evaluation152';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
`;

const Menu = styled.div`
  width: 200px;
  background: #f0f0f0;
  padding: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 60px); // 减去header的高度
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const OnSiteEvaluationPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [kindergartenNames, setKindergartenNames] = useState([]);
    const [selectedKindergarten, setSelectedKindergarten] = useState('');
    const [currentExpertId, setCurrentExpertId] = useState('');
    const [currentRealName, setCurrentRealName] = useState('');
    const [currentAssignedKindergartens, setCurrentAssignedKindergartens] = useState([]);
    const [surveysData, setSurveysData] = useState([]); // 新增状态
    const [lastUpdatedSurvey, setLastUpdatedSurvey] = useState(null);   

    useEffect(() => { 
        if (location.state) {
            const { expertId, realName, assignedKindergartens } = location.state || {};
            setCurrentExpertId(expertId);  
            setCurrentRealName(realName);
            setCurrentAssignedKindergartens(assignedKindergartens);
    
            const fetchKindergartenNames = async () => {
                try {
                    const response = await api.kindergartenNamesByIds(assignedKindergartens);
                    if (response.data.success) {
                        setKindergartenNames(response.data.data);
                    } else {
                        console.error('Failed to fetch kindergarten names');
                    }
                } catch (error) {
                    console.error('Error fetching kindergarten names:', error);
                }
            };
    
            if (assignedKindergartens && assignedKindergartens.length > 0) {
                fetchKindergartenNames();
            }
        }
    }, [location.state]);

    const handleKindergartenChange = (e) => {      
        setSelectedKindergarten(e.target.value);        
        const fetchExpertInfo = async () => {
            try {
                console.log(e.target.value);
                const response = await api.getExpertGrades2AllSurvey(currentExpertId, 2024, e.target.value);
                
                if (response.data.success) {
                    console.log(response.data.data);
                    setSurveysData(response.data.data || []);
                } else {
                    console.error('Failed to fetch expert info');
                    setSurveysData([]);
                }
            } catch (error) {
                console.error('Error fetching expert info:', error);
                setSurveysData([]);
            }
        };

        if (currentExpertId) {
            fetchExpertInfo();
        }
    };

    const handleLogout = () => {
        navigate('/login');
    };

    const handleSurveyUpdate = (surveyNumber) => {
        setLastUpdatedSurvey(surveyNumber);
        // 重新获取所有考查要点的数据
        if (currentExpertId && selectedKindergarten) {
            api.getExpertGrades2AllSurvey(currentExpertId, 2024, selectedKindergarten)
                .then(response => {
                    if (response.data.success) {
                        setSurveysData(response.data.data || []);
                    }
                })
                .catch(error => {
                    console.error('Error fetching updated survey data:', error);
                });
        }
    };

    // 定义菜单项数组
    const menuItems = [
        { surveyNumber: 11, label: '考查要点1.1', component: Evaluation11},
        { surveyNumber: 12, label: '考查要点1.2', component: Evaluation12 },
        { surveyNumber: 13, label: '考查要点1.3', component: Evaluation13 },
        { surveyNumber: 21, label: '考查要点2.1', component: Evaluation21 },
        { surveyNumber: 22, label: '考查要点2.2', component: Evaluation22 },
        { surveyNumber: 31, label: '考查要点3.1', component: Evaluation31 },
        { surveyNumber: 32, label: '考查要点3.2', component: Evaluation32 },
        { surveyNumber: 41, label: '考查要点4.1', component: Evaluation41 },
        { surveyNumber: 42, label: '考查要点4.2', component: Evaluation42 },
        { surveyNumber: 43, label: '考查要点4.3', component: Evaluation43 },
        { surveyNumber: 44, label: '考查要点4.4', component: Evaluation44 },
        { surveyNumber: 51, label: '考查要点5.1', component: Evaluation51 },
        { surveyNumber: 52, label: '考查要点5.2', component: Evaluation52 },
        { surveyNumber: 53, label: '考查要点5.3', component: Evaluation53 },
        { surveyNumber: 54, label: '考查要点5.4', component: Evaluation54 },
        { surveyNumber: 61, label: '考查要点6.1', component: Evaluation61 },
        { surveyNumber: 62, label: '考查要点6.2', component: Evaluation62 },
        { surveyNumber: 63, label: '考查要点6.3', component: Evaluation63 },
        { surveyNumber: 71, label: '考查要点7.1', component: Evaluation71 },
        { surveyNumber: 72, label: '考查要点7.2', component: Evaluation72 },
        { surveyNumber: 73, label: '考查要点7.3', component: Evaluation73 },
        { surveyNumber: 74, label: '考查要点7.4', component: Evaluation74 },
        { surveyNumber: 75, label: '考查要点7.5', component: Evaluation75 },
        { surveyNumber: 76, label: '考查要点7.6', component: Evaluation76 },
        { surveyNumber: 81, label: '考查要点8.1', component: Evaluation81 },
        { surveyNumber: 82, label: '考查要点8.2', component: Evaluation82 },
        { surveyNumber: 83, label: '考查要点8.3', component: Evaluation83 },
        { surveyNumber: 84, label: '考查要点8.4', component: Evaluation84 },
        { surveyNumber: 85, label: '考查要点8.5', component: Evaluation85 },
        { surveyNumber: 86, label: '考查要点8.6', component: Evaluation86 },
        { surveyNumber: 87, label: '考查要点8.7', component: Evaluation87 },
        { surveyNumber: 91, label: '考查要点9.1', component: Evaluation91 },
        { surveyNumber: 92, label: '考查要点9.2', component: Evaluation92 },
        { surveyNumber: 93, label: '考查要点9.3', component: Evaluation93 },
        { surveyNumber: 94, label: '考查要点9.4', component: Evaluation94 },
        { surveyNumber: 101, label: '考查要点10.1', component: Evaluation101 },
        { surveyNumber: 102, label: '考查要点10.2', component: Evaluation102 },
        { surveyNumber: 111, label: '考查要点11.1', component: Evaluation111 },
        { surveyNumber: 112, label: '考查要点11.2', component: Evaluation112 },
        { surveyNumber: 121, label: '考查要点12.1', component: Evaluation121 },
        { surveyNumber: 122, label: '考查要点12.2', component: Evaluation122 },
        { surveyNumber: 131, label: '考查要点13.1', component: Evaluation131 },
        { surveyNumber: 132, label: '考查要点13.2', component: Evaluation132 },
        { surveyNumber: 141, label: '考查要点14.1', component: Evaluation141 },
        { surveyNumber: 142, label: '考查要点14.2', component: Evaluation142 },
        { surveyNumber: 143, label: '考查要点14.3', component: Evaluation143 },
        { surveyNumber: 151, label: '考查要点15.1', component: Evaluation151 },
        { surveyNumber: 152, label: '考查要点15.2', component: Evaluation152 },
    ];
    

    return (
        <Container>
            <Header>
                <span style={{ fontSize: '18px', fontWeight: 'bold' }}>欢迎，{currentRealName}</span>
                <button onClick={handleLogout} style={{ padding: '5px 10px', fontSize: '16px' }}>登出</button>
            </Header>
            <MainContent>
                <Menu>
                    <Select value={selectedKindergarten} onChange={handleKindergartenChange}>
                        <option value="">选择幼儿园</option>
                        {kindergartenNames.map((kindergarten) => (
                            <option key={kindergarten._id} value={kindergarten._id}>
                                {kindergarten.name}
                            </option>
                        ))}
                    </Select>
                    <ul style={{ listStyleType: 'none', padding: 0, fontSize: '16px' }}>
                        {menuItems.map((item) => {
                            const survey = surveysData.find(s => s.surveyNumber === item.surveyNumber);
                            const isCompleted = survey && typeof survey.grades2.grade === 'number' && survey.grades2.grade >= 1 && survey.grades2.grade <= 5;
                            
                            return (
                                <li key={item.surveyNumber} style={{ margin: '10px 0', borderRadius: '5px', transition: 'background-color 0.2s ease-in-out' }}>
                                    <Link
                                        to={`Evaluation${item.surveyNumber}?kindergartenId=${selectedKindergarten}&expertId=${currentExpertId}`}
                                        style={{
                                            display: 'block',
                                            padding: '10px 20px',
                                            textDecoration: 'none',
                                            color: isCompleted ? '#fff' : '#333',
                                            background: isCompleted ? '#007bff' : '#e9ecef',
                                            borderRadius: '5px',
                                            pointerEvents: selectedKindergarten ? 'auto' : 'none',
                                            opacity: selectedKindergarten ? 1 : 0.5,
                                        }}
                                    >
                                        {item.label}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </Menu>
                <Content>
                    <Routes>
                        {menuItems.map((item) => (
                            <Route 
                                key={item.surveyNumber}
                                path={`Evaluation${item.surveyNumber}`}
                                element={React.createElement(item.component, { onSurveyUpdate: handleSurveyUpdate })}
                            />
                        ))}
                    </Routes>
                    <Outlet />
                </Content>
            </MainContent>
        </Container>
    );
};

export default OnSiteEvaluationPage;
